import React from 'react';

interface TextProps {
    text: JSX.Element | Element | string | undefined;
    dark?: boolean;
    leftAlign?: boolean;
}
export default (props: TextProps) => {
    return (
        <div
            className="subtitle"
            style={{ color: props.dark ? '#171F29' : '#fff', textAlign: props.leftAlign ? 'left' : 'center' }}
        >
            {props.text}
        </div>
    );
};
