import React, { useEffect, useState } from 'react';
import HyperModal from 'react-hyper-modal';

import Button from '../../../../components/Button';
import OutlineInput from '../../../../components/OutlineInput';
import { camelize } from '../../../../utils';
import { renderGuideButton } from '../../EnrollmentGuide';

const getDataIdFromLabel = (label: string) => {
    switch (label) {
        case 'Weight':
            return 'weight';
        case 'Heart Rate':
            return 'heartRate';
        case 'Oxygen Saturation':
            return 'oxygenSaturation';
        default:
            return camelize(label);
    }
};

export default ({ isOpen, close, onChange, type }) => {
    const [showGuide, setGuideVisibility] = useState(false);
    const [formValues, setFormValues] = useState<any>();
    const [isFormValid, setFormValidity] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleOnChange = (input) => {
        setFormValues((value) => Object.assign({}, value, input));
    };
    const hasError = () => {
        const value = formValues?.[getDataIdFromLabel(type)];
        if (type === 'Heart rate') return value < 20 || value > 300;
        if (type === 'Weight') return value < 20 || value > 1000;
        return value === 0;
    };
    useEffect(() => {
        if (hasError() || !formValues?.[getDataIdFromLabel(type)]) setFormValidity(false);
        else setFormValidity(true);
        setSubmitted(false);
    }, [formValues]);
    const submit = () => {
        onChange(formValues);
        setSubmitted(true);
    };
    const onGuideClose = () => {
        setGuideVisibility(false);
    };

    if (showGuide) return renderGuideButton(type, onGuideClose);
    return (
        <HyperModal isFullscreen isOpen={isOpen} renderCloseIcon={() => null}>
            <div className="enroll-container measure-form-container">
                <h2 className="deep-grey-700">Enter {type.toLowerCase()}</h2>
                <h3 onClick={(_) => setGuideVisibility(true)} className="red-500">
                    How to measure {type.toLowerCase()}
                </h3>
                <OutlineInput
                    dataId={getDataIdFromLabel(type)}
                    hasError={hasError()}
                    type={'number'}
                    label={type}
                    onChange={handleOnChange}
                />
                <Button text="Submit" action={submit} disabled={!isFormValid || !!submitted} />
                <h3 onClick={close} className="red-500" style={{ textAlign: 'center' }}>
                    Go back
                </h3>
            </div>
        </HyperModal>
    );
};
