import { styled } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

import Header from '../../components/Header';
import './index.css';

const Main = styled('main')({
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    color: '#171f29',
});
const Message = styled('div')({
    fontFamily: 'gilroy-medium',
    fontSize: 18,
    color: '#6e757d',
    textAlign: 'center',
});
const WarningLabel = styled('div')({
    justifyContent: 'center',
    width: '100%',
    padding: 18,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffeef8',
    color: '#171f29',
});
const InfoOutlined = styled(InfoOutlinedIcon)({
    height: 22,
    width: 22,
    color: '#ad0069',
    marginRight: 9,
});
const H2 = styled('h2')({
    fontSize: 36,
    textAlign: 'center',
});
const Container = styled('div')({
    maxWidth: 800,
    backgroundColor: 'white',
    width: '100%',
    padding: 16,
});

const SuccessfulIntake = () => {
    return (
        <>
            <WarningLabel>
                <InfoOutlined />
                Heads up! This website cannot be used for patients who reside outside of the US.
            </WarningLabel>
            <Header dark styles={{ backgroundColor: 'white' }} />
            <Main>
                <Container>
                    <H2>
                        ✅ <br />
                        Information successfully submitted!
                    </H2>
                </Container>
            </Main>
        </>
    );
};

export default SuccessfulIntake;
