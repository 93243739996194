export const isMobile = (() => {
    if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
        return false;
    }
    return /Mobile/.test(navigator.userAgent);
})();

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
    return new Promise((resolve, reject) => {
        resolve(true);
    });
}
// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
    if (typeof obj !== 'object' || Array.isArray(obj) || obj === null) {
        return obj;
    }

    const target: { [name: string]: any } = {};

    for (const key in obj) {
        const val = obj[key];
        if (typeof val !== 'undefined') {
            target[key] = removeUndefineds(val);
        }
    }

    return target as T;
}

export function camelize(str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
}

export function formatPhoneNumber(phoneNumber: string) {
    return phoneNumber.substr(0, 3) + '-' + phoneNumber.substr(3, 3) + '-' + phoneNumber.substr(6, 4);
}

export function dig<O extends object>(object: O, path: string[]) {
    if (path.length === 0) {
        return object;
    }

    let result = object;
    for (const piece of path.slice(0, path.length - 1)) {
        result = result[piece];
        if (typeof result !== 'object') {
            return undefined;
        }
    }

    return result[path[path.length - 1]];
}

export function range(end: number): Array<number> {
    const result: Array<number> = [];
    for (let i = 0; i < end; ++i) {
        result.push(i);
    }
    return result;
}

export function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export async function prepareFilesForUpload(files: File[]) {
    if (!files.length) return [];
    const filesForUpload = files.map(
        async (file) =>
            await file.arrayBuffer().then((arrayBuffer) => ({
                key: file.name,
                mimeType: file.type,
                contentBase64: arrayBufferToBase64(arrayBuffer),
            })),
    );
    return await Promise.all(filesForUpload);
}

export function chunks<T>(array: T[], width: number): T[][] {
    const chunked: T[][] = [];
    for (let i = 0; i < array.length; i += width) {
        chunked.push(array.slice(i, i + width));
    }
    return chunked;
}

/**
 * downloads a pdf from a base64 encoded string
 * if the user is on Safari iOS or Firefox iOS, the iOSCallback will be called because
 * Safari iOS and Firefox iOS do not support the download attribute on anchor tags
 */
export function downloadPDF(base64: string, fileName: string, iOSCallback?: () => void) {
    const linkSource = `data:application/pdf;base64,${base64}`;
    const downloadFileName = `${fileName}.pdf`;

    const isiOS = navigator.userAgent.match(/(iPod|iPhone|iPad)/);
    const isSafariiOS = isiOS && navigator.userAgent.match(/AppleWebKit/);
    const isFirefoxiOS = isiOS && navigator.userAgent.match(/Gecko/);

    // If the user is on Safari iOS or Firefox iOS, open the PDF in a new tab
    if (isSafariiOS || isFirefoxiOS) {
        iOSCallback?.();
    }
    // Otherwise, use the browser's built-in download functionality to download the PDF
    else {
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = downloadFileName;
        downloadLink.click();
    }
}
