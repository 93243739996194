export type ProgramUser = Partial<{
    id: string;
    displayId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    picture: string;
    pictureUrl: string;
    email: string;
    emailVerified: boolean;
    encounters: any[];
    phoneNumber: string;
    birthDate: string;
}>;

export type ProgramPatient = ProgramUser;

export enum UserType {
    PATIENT = 'patient',
    PROVIDER = 'provider',
}
