import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../components/Button';
import BasicPage from '../../components/Layout/BasicPage';
import TextDatePicker, { defaultDatePickerValidationSchema } from '../../components/TextDatePicker';
import TextInputField from '../../components/TextInputField';
import VERIFY_PATIENT_EXISTS from '../../state/network/graphql/queries/verifyPatientExists';
import { validator } from '../../utils/validation';

const useStyles = makeStyles({
    birthDateInput: {
        marginBottom: 24,
    },
    lastNameAndNumberInput: {
        borderBottom: 'solid 1px #d9d9d9',
        marginBottom: 24,
    },
    mobileContactDisclaimer: {
        color: '#434b55',
        fontSize: 16,
        marginTop: 24,
    },
    pageContainer: {
        color: '#171f29',
        fontFamily: 'gilroy',
        fontSize: 18,
        fontWeight: 500,
    },
    pageSubtitle: {
        fontFamily: 'gilroy-medium',
        fontSize: 20,
        fontWeight: 600,
        marginBottom: 30,
    },
    patientVerificationErrorMessage: {
        color: '#e10909',
        fontWeight: 600,
        marginTop: '-24px',
    },
    textInputField: {
        fontFamily: 'gilroy-medium',
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 24,
    },
    viewTermsLink: {
        color: '#d1007e',
        textDecoration: 'none',
    },
});

interface PatientVerificationForm {
    lastName: string;
    day: string;
    month: string;
    year: string;
    mobileNumber: string;
}

const initialValues: PatientVerificationForm = {
    lastName: '',
    day: '',
    month: '',
    year: '',
    mobileNumber: '',
};

/**
 * iRhythm patient identity verification form. Can be used for other clients but currently only set up for iRhythm.
 */
export default function PatientVerification() {
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    const [patientVerificationError, setPatientVerificationError] = useState(false);
    // backend generates these values as `token` and `requestId` to fit the pattern of virtual visit link generation
    const partnerUserId = new URLSearchParams(location.search).get('token');
    const ticketId = new URLSearchParams(location.search).get('requestId');

    useEffect(() => {
        if (!partnerUserId || !ticketId) {
            console.error(`Missing required query params. token=${partnerUserId} requestId=${ticketId}`);
            navigate('/error');
        }
    }, [navigate, partnerUserId, ticketId]);

    const [verifyPatientExists] = useLazyQuery(VERIFY_PATIENT_EXISTS, {
        onCompleted: (result) => {
            const statusJson = result?.verifyPatientExists?.session?.statusJson;
            const sessionId = result?.verifyPatientExists?.session?.id;
            if (statusJson) {
                const parsedStatusJson = JSON.parse(statusJson);
                if (parsedStatusJson.status === 'OK') {
                    navigate(
                        `/p/kyr/schedule-visit?token=${partnerUserId}&requestId=${ticketId}&sessionId=${sessionId}`,
                    );
                    return;
                }
            }
            setPatientVerificationError(true);
        },
        onError: () => setPatientVerificationError(true),
    });

    const handleSubmit = (values) => {
        setPatientVerificationError(false);
        verifyPatientExists({
            variables: {
                dateOfBirth: `${values.year}-${values.month}-${values.day}`,
                lastName: values.lastName,
                partnerUserId: partnerUserId,
                phoneNumber: values.mobileNumber,
                ticketId: ticketId,
            },
        });
    };

    const patientVerificationValidationSchema = Yup.object({
        lastName: validator.lastName.required('Last name is required'),
        day: defaultDatePickerValidationSchema.fields.day,
        month: defaultDatePickerValidationSchema.fields.month,
        year: defaultDatePickerValidationSchema.fields.year,
        mobileNumber: validator.phoneNumber.required('Mobile number is required'),
    });

    return (
        <BasicPage pageTitle="Let's confirm your identity.">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={patientVerificationValidationSchema}
                validateOnBlur
                validateOnMount
            >
                {({ handleSubmit, isValid, dirty }) => {
                    return (
                        <>
                            <div className={styles.pageContainer}>
                                {patientVerificationError && (
                                    <div className={styles.patientVerificationErrorMessage}>
                                        <p>Unable to confirm identity. Please try entering your information again.</p>
                                    </div>
                                )}
                                <div className={styles.lastNameAndNumberInput}>
                                    <TextInputField
                                        name="lastName"
                                        placeholder="Last Name"
                                        className={styles.textInputField}
                                    />
                                    <TextInputField
                                        name="mobileNumber"
                                        placeholder="Mobile Number"
                                        className={styles.textInputField}
                                    />
                                </div>
                                <div className={styles.birthDateInput}>
                                    <Typography component="p" className={styles.pageSubtitle}>
                                        Enter your date of birth:
                                    </Typography>
                                    <TextDatePicker />
                                </div>
                                <Button disabled={!dirty || !isValid} action={handleSubmit} text="Submit" />
                                <div className={styles.mobileContactDisclaimer}>
                                    <p>
                                        By giving us your mobile number, you're giving us permission to contact you via
                                        text.
                                        <a
                                            href="https://www.heartbeathealth.com/terms-of-agreement/"
                                            target="_blank"
                                            className={styles.viewTermsLink}
                                        >
                                            {' '}
                                            View SMS Terms & Conditions
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </>
                    );
                }}
            </Formik>
            <div></div>
        </BasicPage>
    );
}
