import { styled } from '@material-ui/core';
import MUIButton from '@material-ui/core/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import React, { FC } from 'react';

import Header from '../../../components/Header';
import ReportTable from './ReportTable';
import { EcgSubscriptionClassification, WithingsSubscriptionReport } from './types';

const Background = styled('div')({
    backgroundColor: '#fff',
    minHeight: 'calc(100vh - 80px)',
    paddingLeft: 32,
    paddingRight: 32,
});

const Title = styled('div')({
    color: '#2e3192',
    fontSize: 38,
    fontFamily: 'gilroy-extrabold',
    marginBottom: 8,
    paddingTop: 32,
});

const Text = styled('div')({
    color: '#171f29',
    fontSize: 22,
    fontFamily: 'gilroy-medium',
    whiteSpace: 'pre-line',
});

const LI = styled('li')({
    color: '#171f29',
    fontSize: 22,
    fontFamily: 'gilroy-medium',
});

const Subtitle = styled('span')({
    color: '#171f29',
    fontSize: 38,
    fontFamily: 'gilroy',
    letterSpacing: 0,
    lineHeight: 1.2,
});

const SubtitleHighlight = styled('span')({
    color: '#2e3192',
    fontSize: 38,
    fontFamily: 'gilroy-extrabold',
});

const SectionTitle = styled('h3')({
    color: '#2e3192',
    fontSize: 24,
    fontFamily: 'gilroy-extrabold',
    marginTop: 16,
    marginBottom: 16,
});

const Warning = styled('div')({
    border: 'solid 2px #E10909',
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 32,
});

const WarningText = styled('div')({
    color: '#171F29',
    fontFamily: 'gilroy-semibold',
});

const ECGImage = styled('img')({
    maxHeight: 663,
    width: '100%',
    marginTop: 32,
    marginBottom: 32,
    border: 'solid 2px #06080a',
});

const Link = styled('a')({
    color: '#d10072',
    textDecoration: 'none',
    fontFamily: 'gilroy-semibold',
});

const PrimaryButton = styled(({ color, ...other }) => <MUIButton classes={{ label: 'label' }} {...other} />)({
    backgroundColor: '#d10072 !important',
    color: '#fff !important',
    borderRadius: '100px !important',
    fontFamily: 'gilroy-bold',
    fontSize: 18,
    lineHeight: 24,
    height: 64,
    textTransform: 'none',
    padding: 12,
    width: '100%',
    overflow: 'hidden',
    [`& .label`]: {
        fontFamily: 'gilroy-semibold',
    },
});

const SecondaryButton = styled(({ color, ...other }) => <MUIButton classes={{ label: 'label' }} {...other} />)({
    backgroundColor: '#fff !important',
    color: '#d10072 !important',
    border: 'solid #d1007e 2px !important',
    borderRadius: '100px !important',
    fontSize: 18,
    lineHeight: 24,
    height: 64,
    textTransform: 'none',
    padding: 12,
    marginTop: 16,
    marginBottom: 40,
    paddingLeft: 32,
    paddingRight: 32,
    [`& .label`]: {
        fontFamily: 'gilroy-semibold',
    },
});

interface DescriptionProps {
    classification: EcgSubscriptionClassification;
}

function ClassificationDescription(props: DescriptionProps) {
    switch (props.classification) {
        case EcgSubscriptionClassification.NormalSinusRhythm:
            return (
                <Text>
                    Normal sinus rhythm is the normal rhythm of the heart, beating between 60 and 100 beats per minute.
                </Text>
            );
        case EcgSubscriptionClassification.SinusArrhythmia:
            return (
                <Text>
                    Sinus arrhythmia means there is some variation in your heartbeat. It is considered normal. Your
                    heart rhythm and rate can change over time.
                </Text>
            );
        case EcgSubscriptionClassification.SinusWithPACS:
            return (
                <Text>
                    Normal sinus rhythm is the normal rhythm of the heart. “Premature atrial contractions,” also called
                    PACs, are early or extra heart beats. These heart beats start in the top chambers of the heart. They
                    can give you a feeling of fluttering, pounding, or skipped heartbeats. PACs can happen on their own.
                    They can also be worsened by fatigue, caffeine, tobacco, stress, or drugs. PACs are common and
                    usually do not need treatment.
                </Text>
            );
        case EcgSubscriptionClassification.SinusWithPVCS:
            return (
                <Text>
                    Normal sinus rhythm is the normal rhythm of the heart. Premature ventricular contractions, also
                    called PVCs, are early or extra heart beats. These heart beats start in the bottom chambers of the
                    heart. They can give you a feeling of fluttering, pounding, or skipped heartbeats. PVCs can happen
                    on their own. They can get worse with heart disease, anemia, fatigue, caffeine, tobacco, stress, or
                    drugs. PVCs are common. Depending on the cause, they may or may not need treatment.
                </Text>
            );
        case EcgSubscriptionClassification.SinusBradycardia:
            return (
                <>
                    <Text>
                        Sinus bradycardia is a type of slow heartbeat. Sinus bradycardia means that the rhythm of the
                        heart is normal, but the heartbeat is slow.
                    </Text>
                    <br />
                    <Text>
                        Your heart rhythm and rate changes over the course of the day depending on your activity level.
                        Sinus bradycardia is more likely to happen while you are sleeping or at rest. For most people,
                        especially younger people or athletes, this is normal.
                    </Text>
                </>
            );
        case EcgSubscriptionClassification.SinusTachycardia:
            return (
                <>
                    <Text>
                        Sinus tachycardia is a type of fast heartbeat. Sinus tachycardia means that the rhythm of the
                        heart is normal, but the heartbeat is fast.
                    </Text>
                    <br />
                    <Text>
                        Your heart rhythm and rate changes over the course of the day depending on your activity level.
                        It’s normal for sinus tachycardia to happen while you are exercising. Our heart rate goes up
                        when we exercise and comes back down when we rest. Sinus tachycardia can sometimes be a sign of
                        other health issues.
                    </Text>
                </>
            );
        case EcgSubscriptionClassification.AtrialFibrillation:
            return (
                <>
                    <Text>
                        Atrial fibrillation, sometimes called ‘A-fib,’ is an abnormal heartbeat that occurs when the top
                        chambers of the heart beat irregularly. This may cause symptoms such as skipped heart beats,
                        fatigue, trouble breathing, and more. It also may cause the heart to beat fast. However, for
                        some people, it may not cause any symptoms.
                    </Text>
                    <br />
                    <Text>
                        Atrial fibrillation affects at least 2.7 million Americans yearly. When untreated, it can lead
                        to complications such as stroke, heart failure, or other heart-related issues.
                    </Text>
                </>
            );
        case EcgSubscriptionClassification.AtrialFlutter:
            return (
                <>
                    <Text>
                        Atrial flutter is an abnormal heartbeat that occurs when the top chambers of the heart beat
                        irregularly. This may cause symptoms such as skipped heart beats, fatigue, trouble breathing,
                        and more. It also may cause the heart to beat fast. However, for some people, it may not cause
                        any symptoms.
                    </Text>
                    <br />
                    <Text>
                        When untreated, atrial flutter can lead to complications, such as stroke, heart failure, or
                        other heart-related issues.
                    </Text>
                </>
            );
        case EcgSubscriptionClassification.SupraVentricularTachycardia:
            return (
                <Text>
                    Supraventricular tachycardia (SVT) is an abnormal heart rhythm that develops in the top chambers of
                    the heart and causes it to beat fast and regularly. It can start and stop very abruptly. This may
                    cause symptoms of a fast heart beat, lightheadedness, difficulty breathing, and more. However, for
                    some people, it may not cause any symptoms.
                </Text>
            );
        case EcgSubscriptionClassification.VentricularTachycardia:
            return (
                <Text>
                    Ventricular tachycardia (VT) is an abnormal heart rhythm that develops in the bottom chambers of the
                    heart. It causes the heart to beat fast and regularly. Ventricular tachycardia can start and stop
                    very abruptly. This may cause a fast heartbeat, lightheadedness, chest pain, or difficulty
                    breathing. For longer, more serious episodes, it can cause fainting or a cardiac arrest, which is
                    when the heart stops beating suddenly.
                </Text>
            );
        case EcgSubscriptionClassification.HeartBlock:
            return (
                <Text>
                    Heart block means the electricity at the top part of the heart doesn’t get to the bottom part of the
                    heart normally. This causes the heart rhythm to slow down. There are different types of heart block.
                    Some are more serious than others. Heart block may cause symptoms such as fatigue, difficulty
                    breathing, lightheadedness or fainting. However, for some people, it may not cause any symptoms.
                </Text>
            );
        case EcgSubscriptionClassification.OtherArrhythmia:
            return (
                <Text>
                    “Other arrhythmia” means that your ECG may have an abnormal heart rhythm. This can range from normal
                    to more serious. You may need another ECG or other test to figure out the specific type of heart
                    rhythm you have.
                </Text>
            );
        default:
            return null;
    }
}

function NextStepsDescription(props: DescriptionProps) {
    switch (props.classification) {
        case EcgSubscriptionClassification.SinusWithPACS:
            return (
                <Text>If your ECG shows PACs, talk to your doctor. You may want to bring a copy of this report.</Text>
            );
        case EcgSubscriptionClassification.SinusWithPVCS:
            return (
                <>
                    <Text>
                        For most people, PVCs do not need treatment. For some, it may be a sign of a heart condition.
                    </Text>
                    <br />
                    <Text>
                        If your ECG shows PVCs, talk to your doctor. They may suggest more tests to figure out the cause
                        of the PVCs. You may want to bring a copy of this report.
                    </Text>
                </>
            );
        case EcgSubscriptionClassification.SinusBradycardia:
            return (
                <Text>
                    Most people with sinus bradycardia, especially if they do not feel it or if it only happens while
                    they are sleeping, do not need any treatment. For these people, their normal heart rhythm is slow
                    and it becomes faster with activity. For some people, sinus bradycardia may be a sign of a heart
                    problem or another health issue. If your ECG shows sinus bradycardia, talk to your doctor. You may
                    want to bring a copy of this report.
                </Text>
            );
        case EcgSubscriptionClassification.SinusTachycardia:
            return (
                <>
                    <Text>
                        Most people with sinus tachycardia do not need treatment. For most people, their heartbeat
                        becomes faster with activity, which is normal. If you were physically active (like walking fast)
                        right before you took your ECG, try taking it again while you are sitting or resting quietly.
                    </Text>
                    <br />
                    <Text>
                        If someone has sinus tachycardia while resting, it may be a sign of a heart problem or another
                        health issue. If your ECG shows sinus tachycardia, then speak to your doctor about whether or
                        not this is expected for you. You may want to bring a copy of this report.
                    </Text>
                </>
            );
        case EcgSubscriptionClassification.AtrialFibrillation:
            return (
                <Text>
                    Your ScanWatch shows possible atrial fibrillation. It is important to see a doctor as soon as
                    possible. They will ask some more questions and may run some more tests to confirm if you have a
                    heart condition. They may recommend treatment or a referral to a cardiologist. You may want to bring
                    a copy of this report.
                </Text>
            );
        case EcgSubscriptionClassification.AtrialFlutter:
            return (
                <Text>
                    Your ScanWatch shows possible atrial flutter. It is important to see a doctor as soon as possible.
                    They will ask some more questions and may run some more tests to confirm if you have a heart
                    condition. They may recommend treatment or a referral to a cardiologist. You may want to bring a
                    copy of this report.
                </Text>
            );
        case EcgSubscriptionClassification.SupraVentricularTachycardia:
            return (
                <Text>
                    Your ScanWatch suggests possible supraventricular tachycardia. It is important to see a doctor as
                    soon as possible. They will ask some more questions and may run some more tests to confirm if you
                    have a heart condition. They may recommend treatment or a referral to a cardiologist. You may want
                    to bring a copy of this report.
                </Text>
            );
        case EcgSubscriptionClassification.VentricularTachycardia:
            return (
                <Text>
                    Your ScanWatch suggests possible ventricular tachycardia. It is important to see a doctor as soon as
                    possible. They will ask some more questions and may run some more tests to confirm if you have a
                    heart condition. They may recommend treatment or a referral to a cardiologist. You may want to bring
                    a copy of this report.
                </Text>
            );
        case EcgSubscriptionClassification.HeartBlock:
            return (
                <Text>
                    Your ScanWatch suggests possible heart block. It is important to see a doctor as soon as possible.
                    They will ask some more questions and may run some more tests to confirm if you have a heart
                    condition. They may recommend treatment or a referral to a cardiologist. You may want to bring a
                    copy of this report.
                </Text>
            );
        case EcgSubscriptionClassification.OtherArrhythmia:
            return (
                <Text>
                    Your ScanWatch suggests a possible other arrhythmia. It is important to see a doctor as soon as
                    possible. They will ask some more questions and may run some more tests to confirm if you have a
                    heart condition. They may recommend treatment or a referral to a cardiologist. You may want to bring
                    a copy of this report.
                </Text>
            );

        default:
            return null;
    }
}

function KeepHealthyDescription(props: DescriptionProps) {
    switch (props.classification) {
        case EcgSubscriptionClassification.VentricularTachycardia:
            return null;
        case EcgSubscriptionClassification.HeartBlock:
            return null;
        default:
            return (
                <Text>
                    Help keep your heart healthy by:
                    <ul>
                        <LI>Eating healthy and nutritious foods like fruits, vegetables, and whole grains.</LI>
                        <LI>
                            Getting regular physical activity. Thirty minutes of exercise per day at least five days per
                            week can lower your risk of heart disease. It can also improve your mood and sleep. Before
                            starting a new exercise program, always check with your doctor.
                        </LI>
                        <LI>Managing your stress and getting rest.</LI>
                        <LI>Avoiding tobacco and nicotine products.</LI>
                    </ul>
                </Text>
            );
    }
}

const nameFromClassification = (classification: EcgSubscriptionClassification) => {
    switch (classification) {
        case EcgSubscriptionClassification.SinusWithPACS:
            return 'sinus with PACs';
        case EcgSubscriptionClassification.SinusWithPVCS:
            return 'sinus with PVCs';
        default:
            return classification.toLowerCase().replace(/_/g, ' ');
    }
};

interface Props {
    report: WithingsSubscriptionReport;
    smallWidth: boolean;
    setShowImage: (showImage: boolean) => void;
    setReportId: (val?: string) => void;
}

const ReportDetail: FC<Props> = (props) => {
    const { report, smallWidth, setShowImage, setReportId } = props;

    const handleShowImage = () => setShowImage(true);
    const handleBackButtonPress = () => setReportId();

    return (
        <div>
            <Header withings styles={{ justifyContent: 'space-between' }} />
            <Background>
                <br />
                <ReportTable report={report} />
                <Title>Hi {report?.patient?.firstName},</Title>
                <Subtitle>
                    A board certified cardiologist from Heartbeat Health reviewed your ECG and it shows{' '}
                    <SubtitleHighlight>{nameFromClassification(report.ecgClassification)}</SubtitleHighlight> with{' '}
                    <SubtitleHighlight>{report.ventricularRate.toLowerCase()}</SubtitleHighlight> ventricular rate and{' '}
                    <SubtitleHighlight>{report.morphology.toLowerCase().replace(/_/g, ' ')}</SubtitleHighlight>{' '}
                    appearing morphology.
                </Subtitle>
                <br />
                <br />
                <SectionTitle>About this report</SectionTitle>
                <Text>
                    This report is a summary of your electrocardiogram or ‘ECG’ or ‘EKG’. An ECG records the electrical
                    signal from your heart. That electrical signal is what triggers the heart to beat. An ECG can show
                    signs of heart disease or abnormalities in your heartbeat. The type of ECG on your ScanWatch can
                    show abnormalities in your heartbeat. But this type of ECG cannot diagnose a heart attack or all
                    types of heart problems.
                </Text>
                <br />
                <Text>
                    On the report, you may see the term “ventricular rate.” This means how fast the heart is beating.
                    The heart usually beats about 60-100 beats per minute. This normally goes up when we exercise or
                    down when we rest. But faster or slower rates may suggest a heart condition or other health issue to
                    follow-up on with your doctor.
                </Text>
                <br />
                <Text>
                    You may also see the word “morphology.” This means the ECG shape, which has peaks and valleys.
                    Abnormal morphology may require more tests from your doctor.
                </Text>
                <br />
                <SectionTitle>What is {report.ecgClassification.toLowerCase().replace(/_/g, ' ')}?</SectionTitle>
                <ClassificationDescription classification={report.ecgClassification} />
                <br />
                <SectionTitle>Next steps</SectionTitle>
                <NextStepsDescription classification={report.ecgClassification} />
                <br />
                <KeepHealthyDescription classification={report.ecgClassification} />
                <br />
                <Text>
                    If you have questions about this interpretation, please contact:{' '}
                    <Link href="mailto:clinical@heartbeathealth.com">clinical@heartbeathealth.com</Link>
                </Text>
                <Warning style={smallWidth ? { flexDirection: 'column' } : { flexDirection: 'row' }}>
                    <ReportProblemOutlinedIcon
                        color="error"
                        style={smallWidth ? { alignSelf: 'flex-start', marginBottom: 16 } : undefined}
                    />
                    <WarningText style={!smallWidth ? { marginLeft: 32 } : undefined}>
                        If you think you may have a medical emergency – including chest pain, trouble breathing, or
                        fainting – call your doctor or 911 immediately. Please do not disregard, avoid, or delay getting
                        medical or health-related advice from your health care professional because of something you may
                        have read or learned from this report.
                    </WarningText>
                </Warning>

                <ECGImage src={report.ecgImageUrl} />
                {smallWidth ? (
                    <>
                        <PrimaryButton endIcon={<OpenInNewIcon />} onClick={handleShowImage}>
                            Expand
                        </PrimaryButton>
                        <SecondaryButton
                            onClick={handleBackButtonPress}
                            startIcon={<ChevronLeftIcon />}
                            style={{ width: '100%', overflow: 'hidden', fontFamily: 'gilroy-bold' }}
                        >
                            Back
                        </SecondaryButton>
                    </>
                ) : (
                    <div style={{ paddingBottom: 40 }}>
                        {/* @ts-ignore */}
                        <SecondaryButton onClick={handleBackButtonPress} startIcon={<ChevronLeftIcon />}>
                            Back
                        </SecondaryButton>
                    </div>
                )}
            </Background>
        </div>
    );
};

export default ReportDetail;
