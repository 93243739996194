import { TextField } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';
import { useCallback } from 'react';

interface TextInputFieldProps {
    name: string;
    type?: string;
    placeholder: string;
    helperText?: string;
    className?: string;
    onChange?(value: string, handleChange: (v: string) => void): void;
    onBlur?(handleBlur: () => void): void;
}

function TextInputField({ name, type, placeholder, helperText, className, onChange, onBlur }: TextInputFieldProps) {
    const [input, meta, helper] = useField<string>(name);
    const inputType = type ?? 'text';
    const handleChange = useCallback(
        (value: string) => {
            if (onChange) {
                onChange(value, (v) => input.onChange(name)(v));
            } else {
                input.onChange(name)(value);
            }
        },
        [input, name, onChange],
    );

    const handleBlur = useCallback(() => {
        if (onBlur) {
            onBlur(() => helper.setTouched(true));
        } else {
            helper.setTouched(true);
        }
    }, [helper, onBlur]);

    const formatErrorMessage = (error: string | undefined) => {
        return error?.replace(new RegExp(`${name}`, 'g'), placeholder)?.replace(/\*/g, '');
    };
    const errorMessage = meta.touched ? formatErrorMessage(meta.error) ?? helperText : '';
    return (
        <TextField
            type={inputType}
            error={typeof errorMessage === 'string' && errorMessage !== ''}
            name={name}
            label={placeholder}
            onChange={(e) => {
                handleChange(e.target.value);
            }}
            variant="outlined"
            onBlur={handleBlur}
            value={input.value ?? ''}
            helperText={errorMessage}
            className={className}
        />
    );
}

export default TextInputField;
