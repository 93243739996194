import { styled } from '@material-ui/core';
import React from 'react';

import logo from '../../assets/images/HB_logo_horizontal.png';
import { deepGrey700 } from '../../utils/colors';

export const CopyContainer = styled('div')({
    margin: '8px 0',
});

export const ButtonGroup = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
});

export const TopSpacer = styled('div')({
    marginTop: '24px',
});

export const BigText = styled('div')({
    fontSize: '55px',
    lineHeight: '40px',
    marginTop: '21px',
    marginBottom: '24px',
});

export const Container = styled('div')({
    maxWidth: '800px',
    margin: '0 auto',
    overflow: 'auto',
    backgroundColor: 'white',
    flexDirection: 'column',
    color: deepGrey700,
    display: 'flex',
});

export const Header = styled('div')({
    alignSelf: 'start',
    padding: '24px 16px',
});

export const Logo = styled('img')({
    maxHeight: '20px',
});

export const Body = styled('div')({
    padding: '18px 16px',
    display: 'flex',
    flexDirection: 'column',
});

export const LogoHeader = () => (
    <Header>
        <Logo src={logo} alt="HeartBeat" />
    </Header>
);
