import { Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import jsPDF from 'jspdf';
import React from 'react';

import Button from '../../components/Button';
import BasicPage from '../../components/Layout/BasicPage';
import { downloadPDF } from '../../utils';
import { healthyRed500 } from '../../utils/colors';
import { base64 as noFindingsBase64Pdf } from './NoFindingsBase64PDF';

const useStyles = makeStyles({
    contentText: {
        color: '#171f29',
        fontSize: 20,
        fontFamily: 'gilroy-medium',
        whiteSpace: 'pre-line',
        marginBottom: '16px',
    },
    link: {
        color: healthyRed500,
        textDecoration: 'none',
    },
    stack: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'space-between',
    },
});

const Bold = styled('b')({
    color: '#171f29',
    fontSize: 20,
    fontWeight: 'bold',
    fontFamily: 'gilroy-extrabold',
    whiteSpace: 'pre-line',
});

/**
 * Download the PDF on mobile iOS browsers
 */
const downloadImageOnMobileiOS = () => {
    const canvas = document.createElement('canvas');
    const img = new Image();
    img.src = require('../../assets/images/No_Findings_Zio_Read.png');
    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0, img.width, img.height);

        const dataUrl = canvas.toDataURL('image/png');

        const pdf = new jsPDF('p', 'pt', [canvas.width, canvas.height]);

        // Center the image horizontally and vertically
        const x = (pdf.internal.pageSize.getWidth() - canvas.width) / 2;
        const y = (pdf.internal.pageSize.getHeight() - canvas.height) * 0.75;

        pdf.addImage(dataUrl, 'PNG', x, y, canvas.width, canvas.height);

        pdf.save('No_Findings_Zio_Read.pdf');
    };
};

/**
 * Generic page for normal zio read results, abnormal results will have a followup visit instead of this page.
 *
 * @remarks NOTE: changes made to this page also need to be applied to the ZioReadNoFindings.pdf file
 */
export default function NoFindings() {
    const styles = useStyles();

    return (
        <BasicPage pageTitle="Update on your findings." pageWidthType="wide">
            <div className={styles.stack}>
                <Typography component="p" className={styles.contentText}>
                    Heartbeat's team of clinicians and cardiologists have reviewed your Zio patch and determined that{' '}
                    <Bold>no clinically significant findings were present.</Bold>
                </Typography>
                <Typography className={styles.contentText}>
                    If you have any questions/concerns about your results, please contact us to schedule an appointment
                    with one of our providers.
                </Typography>
                <Typography className={styles.contentText}>
                    Phone number:{' '}
                    <a className={styles.link} href="tel:+1866-826-5888">
                        866-826-5888
                    </a>
                    <br />
                    Email:{' '}
                    <a
                        className={styles.link}
                        href="mailto:hello@heartbeathealth.com?subject=Know Your Rhythm Support Request"
                    >
                        hello@heartbeathealth.com
                    </a>
                </Typography>
                <Typography className={styles.contentText}>
                    *Please note that this was based only on your two-week monitoring period and you could still have an
                    abnormal heart rhythm outside this window.
                </Typography>
            </div>
            <div style={{ maxWidth: '312px' }}>
                <Button
                    text="Download a report copy"
                    action={() => downloadPDF(noFindingsBase64Pdf, 'ZioReadNoFindings', downloadImageOnMobileiOS)}
                />
            </div>
        </BasicPage>
    );
}
