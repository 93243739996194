import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { styled, withStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

export const CustomCheckbox = withStyles({
    root: {
        borderColor: 'transparent!important',
        color: '#171f29!important',
        backgroundColor: 'transparent!important',
        paddingRight: 0,
        right: 10,
        bottom: 2,
        height: 20,
        '&$checked': {
            color: '#d1007e!important',
        },
    },
    checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);
const Label = styled('label')({
    color: '#171f29',
    fontFamily: 'gilroy-medium',
    fontSize: 16,
});

interface Props {
    label: ReactNode;
    onChange?: ((params: any) => void) | (() => void) | null;
    dataId: string;
    labelStyle?: React.CSSProperties;
    checked?: boolean;
}

export default (props: Props) => {
    const { label, onChange, dataId, labelStyle, checked } = props;
    const handleOnChange = (event) => {
        onChange && onChange({ [dataId]: event.target.checked });
    };
    return (
        <>
            <CustomCheckbox
                checked={checked}
                name={dataId}
                onChange={handleOnChange}
                inputProps={{
                    'aria-label': dataId,
                }}
                disabled={!onChange}
            />
            <Label style={labelStyle ? labelStyle : undefined}>{label}</Label>
        </>
    );
};
