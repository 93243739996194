import { gql } from '@apollo/client';

export const GET_REPORT_LIST = gql`
    query ecgSubscriptionReportList($token: String!) {
        ecgSubscriptionReportList(token: $token) {
            statusCode
            reports {
                id
                createdAt
                ecgCaptureDate
            }
            patient {
                id
                firstName
                middleName
                lastName
                displayId
            }
            errorMessage
        }
    }
`;

export const GET_REPORT_BY_ID = gql`
    query ecgSubscriptionReport($reportId: String!) {
        ecgSubscriptionReport(reportId: $reportId) {
            statusCode
            report {
                createdAt
                patient {
                    id
                    displayId
                    firstName
                    middleName
                    lastName
                    birthDate
                }
                ecgImageUrl
                ecgCaptureDate
                ecgClassification
                ventricularRate
                morphology
                providerName
            }
            errorMessage
        }
    }
`;
