import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import * as Yup from 'yup';

import TextInputField from '../TextInputField';

export const defaultDatePickerValidationSchema = Yup.object({
    day: Yup.string()
        .matches(/^\d{2}$/, 'Must be 2 digits')
        .required('Day field is required'),
    month: Yup.string()
        .matches(/^\d{2}$/, 'Must be 2 digits')
        .required('Month field is required'),
    year: Yup.string()
        .matches(/^\d{4}$/, 'Must be 4 digits')
        .required('Year field is required'),
});

interface Props {
    showHelperText?: boolean;
}
const TextDatePicker = ({ showHelperText = true }: Props) => {
    const styles = useStyles();
    return (
        <>
            <div style={{ display: 'flex', gap: '16px' }}>
                <div className={styles.inputShort}>
                    <TextInputField name="month" placeholder="MM" />
                </div>
                <div className={styles.inputShort}>
                    <TextInputField name="day" placeholder="DD" />
                </div>
                <div className={styles.inputLong}>
                    <TextInputField name="year" placeholder="YYYY" />
                </div>
            </div>
            {showHelperText && (
                <Typography component="p" className={styles.helperText}>
                    For example: 07 21 1982
                </Typography>
            )}
        </>
    );
};

const useStyles = makeStyles({
    inputShort: {
        width: '80px',
    },
    inputLong: {
        width: '136px',
    },
    helperText: {
        fontFamily: 'gilroy-medium',
        fontSize: '18px',
        fontWeight: 500,
        color: '#6e757d',
        padding: '16px 0',
    },
});

export default TextDatePicker;
