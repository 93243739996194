import React from 'react';
import HyperModal from 'react-hyper-modal';
import { useLocation } from 'react-router-dom';

import { HOW_TO_MEASURE } from '../../../constants';
import '../index.css';

export const renderGuideButton = (title: string, onClose?: () => void) => {
    return (
        <HyperModal
            isFullscreen
            renderOpenButton={(requestOpen) => {
                return (
                    <div onClick={requestOpen} className="guide-button">
                        <h3 className="subtitle">{title}</h3>
                        <h3 className="subtitle">→</h3>
                    </div>
                );
            }}
            classes={{ closeIconClassName: 'modal-close-icon-container' }}
            renderCloseIcon={() => <span className="modal-close-icon">✕</span>}
            beforeClose={onClose}
        >
            <div className="modal-container">
                <h2 style={{ textAlign: 'left', color: '#ffffff' }}>{title}</h2>
                <p className="enrollment-guide-text how-to-measure">{HOW_TO_MEASURE[title] || ''}</p>
            </div>
        </HyperModal>
    );
};

interface EnrollmentGuideState {
    patientName: string;
}

export default () => {
    const location = useLocation();
    const patientName = location.state && (location.state as EnrollmentGuideState).patientName;

    return (
        <div className="enroll-container blue-800-bg guide-container" style={{}}>
            <div style={{ padding: 16 }}>
                <h3 className="subtitle blue-200" style={{ textAlign: 'left' }}>
                    HEARTBEAT HEALTH
                </h3>
                <h2 style={{ color: '#ffffff' }}>
                    {patientName ? patientName + ', w' : 'W'}elcome to Heartbeat Measure! Here's what to expect.
                </h2>
                <ol>
                    <li>
                        We've just sent you your first link to enter your vitals if you already have measuring devices
                        at home.
                    </li>
                    <li>Each day, you will receive a text message reminder to record your vitals.</li>
                    <li>Tap the link in the text when you are ready to input your numbers.</li>
                    <li>
                        You don't have to input all of your vitals at once, as you can return to the link throughout the
                        day.
                    </li>
                </ol>
            </div>
            <hr />
            <div className="guide-button blue-800-bg">
                <h3 className="subtitle" style={{ textAlign: 'left' }}>
                    LEARN MORE ABOUT
                </h3>
            </div>
            <div className="guide-buttons">
                {renderGuideButton('Blood pressure')}
                {renderGuideButton('Weight')}
                {renderGuideButton('Heart rate')}
            </div>
        </div>
    );
};
