import { Typography } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import KyrCoverImg from '../../assets/images/studio_zio.png';
import Button from '../../components/Button';
import Header from '../../components/Header';

const Bold = styled('b')({
    color: '#171f29',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'gilroy-medium',
    whiteSpace: 'pre-line',
});

const FAQ = [
    {
        title: 'Who is Heartbeat Health?',
        text: 'We are a virtual cardiovascular practice with board-certified physicians, licensed in all 50 states and Washington, D.C., helping patients across the country manage their heart care.',
    },
    {
        title: 'Heartbeat partnership with Know Your Rhythm.',
        text: (
            <>
                Heartbeat is excited to partner with the <Bold>Know Your Rhythm </Bold> program to verify your access to
                a Zio patch. Click the button below to schedule a visit with a virtual-cardiologist and get started.
            </>
        ),
    },
];

const useStyles = makeStyles({
    pageContainer: {
        display: 'flex',
        minHeight: 'calc(100vh - 80px)', // set height of the page content to screen size minus header height
        maxWidth: '100%',
    },
    contentContainer: {
        width: '100%',
        '@media (min-width: 1024px)': {
            maxWidth: '728px', // on desktop, limit text container width
        },
    },
    main: {
        padding: '80px 16px 24px 16px',
        backgroundImage: 'linear-gradient(#ffffff, #e8e9eb)',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '100%',
        '@media (min-width: 1024px)': {
            flex: '0.6', // on desktop, have text section grow to 60% of screen
        },
    },
    kyrCoverImg: {
        backgroundImage: `url(${KyrCoverImg})`,
        backgroundPosition: 'right',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        '@media (min-width: 1024px)': {
            flex: '0.4', // on desktop, have image section grow to 40% of screen
            width: '100%',
        },
    },
    faqContainer: {
        paddingBottom: '16px',
    },
    // the below text styles can be removed once we have a mui theme setup
    pageTitle: {
        letterSpacing: '-1.92px',
        fontSize: '48px',
        fontFamily: 'gilroy-extrabold',
        color: '#171f29',
        fontWeight: 800,
        '@media (min-width: 768px)': {
            fontSize: '64px',
        },
    },
    faqTitle: {
        color: '#4e52d3',
        fontSize: 22,
        fontFamily: 'gilroy-bold',
        marginBottom: 8,
        marginTop: 32,
    },
    faqText: {
        color: '#171f29',
        fontSize: 20,
        fontFamily: 'gilroy-medium',
        whiteSpace: 'pre-line',
    },
});

/**
 * Page that iRhythm / Zio patch patients will be directed to.
 * This is the landing page to take them into scheduling their virtual visit.
 */
export default function IRhythmLandingPage() {
    const styles = useStyles();
    const navigate = useNavigate();
    const location = useLocation();
    // backend generates these values as `token` and `requestId` to fit the pattern of virtual visit link generation
    const partnerUserId = new URLSearchParams(location.search).get('token');
    const ticketId = new URLSearchParams(location.search).get('requestId');

    useEffect(() => {
        if (!partnerUserId || !ticketId) {
            console.error(`Missing required query params. token=${partnerUserId} requestId=${ticketId}`);
            navigate('/error');
        }
    }, [partnerUserId, ticketId]);

    return (
        <>
            <Header />
            <div className={styles.pageContainer}>
                <main className={styles.main}>
                    <div className={styles.contentContainer}>
                        <Typography component="h1" className={styles.pageTitle}>
                            Unlock your heart health.
                        </Typography>
                        <div className={styles.faqContainer}>
                            {FAQ.map((faq, index) => (
                                <div key={index}>
                                    <Typography component="h2" className={styles.faqTitle}>
                                        {faq.title}
                                    </Typography>
                                    <Typography component="p" className={styles.faqText}>
                                        {faq.text}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                        <Button
                            action={() =>
                                navigate(`/p/kyr/verify-patient?token=${partnerUserId}&requestId=${ticketId}`)
                            }
                            text="Schedule your Virtual Visit"
                        />
                    </div>
                </main>
                <div className={styles.kyrCoverImg}></div>
            </div>
        </>
    );
}
