import { RumEvent, datadogRum } from '@datadog/browser-rum';

import { isProduction } from '../env';

type RumEventActionType = {
    target: {
        name: string;
    };
};

export const initCrashReporting = () => {
    // Environment variables like REACT_APP_**** only exist in apps deployed via AWS Amplify for dev2, dev & prod
    if (!process.env.REACT_APP_ENVIRONMENT_NAME) return;
    const isPreview = document.location.host.slice(0, 3) === 'pr-';
    if (isPreview) return;
    datadogRum.init({
        applicationId: '12d6c658-2cbd-48b3-a769-99b61a7ca428',
        clientToken: 'pub07c72ac4cfed77e92f7a2840c956a58e',
        site: 'datadoghq.com',
        service: 'web-televisit',
        version: '1.0.0',
        env: process.env.REACT_APP_ENVIRONMENT_NAME,
        sessionSampleRate: 100,
        trackUserInteractions: true,
        beforeSend: (event: RumEvent) => {
            /* Prevents logging images/PDFs */
            if (event.type === 'resource') {
                return false;
            }

            /* Prevents logging names in HTML elements */
            if (event.action && (event.action as RumEventActionType).target) {
                const eventAction = event.action as RumEventActionType;
                eventAction.target.name = '';
            }
            return true;
        },
    });

    if (!isProduction()) {
        datadogRum.startSessionReplayRecording();
    }
};
