import { MenuItem, OutlinedTextFieldProps, TextField } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';

interface DropdownItem {
    value: string;
    label: string;
}

interface DropdownProps extends Omit<OutlinedTextFieldProps, 'variant'> {
    formikFieldName: string;
    options: DropdownItem[];
}

const DropdownField = ({ formikFieldName, options, ...rest }: DropdownProps) => {
    const [field, meta, helper] = useField<string>(formikFieldName);
    const hasError = meta.touched && !!meta.error;

    // we use <TextField/> with `select` attribute instead of <Select/> in order to correctly display labels
    // see https://v4.mui.com/components/text-fields/#select
    // see https://stackoverflow.com/questions/67064682/mui-outlined-select-label-is-not-rendering-properly
    return (
        <TextField
            select
            variant="outlined"
            value={field.value}
            onChange={(event) => helper.setValue(event.target.value)}
            onBlur={() => helper.setTouched(true)}
            error={hasError}
            helperText={hasError ? meta.error : null}
            {...rest}
        >
            {options.map(({ value, label }, index) => (
                <MenuItem key={`${label}-${index}`} value={value}>
                    {label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default DropdownField;
