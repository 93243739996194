import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Text from '../../components/Text';
import Title from '../../components/Title';

interface Props {
    errorMessage: string;
}

export default () => {
    const state = useLocation().state as Props;
    let errorMessage = state && state.errorMessage;
    if (typeof errorMessage !== 'string') errorMessage = 'Request failed';

    return (
        <div className="container">
            {errorMessage && (
                <Snackbar
                    ContentProps={{ style: { backgroundColor: '#e10909', color: 'white', fontWeight: 600 } }}
                    autoHideDuration={10000}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    message={errorMessage}
                />
            )}
            <Title text="Sorry, this session has expired." />
            <Text text="Please contact your provider directly for a new visit link." />
        </div>
    );
};
