import { styled } from '@material-ui/styles';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Checkbox';
import Header from '../../../components/Header';
import { redirectToHome } from '../../../routes';
import { isProduction } from '../../../utils/env';

const Bold = styled('b')({
    color: '#171f29',
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'gilroy-medium',
    whiteSpace: 'pre-line',
});

const FAQ = [
    {
        title: 'Who is Heartbeat Health?',
        text: 'We are a virtual cardiovascular practice with board-certified physicians, licensed in all 50 states and Washington, D.C., helping patients across the country manage their heart care. \n\nHeartbeat is excited to partner with Withings to assist with activation of the ECG readings on your Withings ScanWatch and Withings BodyScan.',
    },
    {
        title: 'You’ll soon be on your way.',
        text: 'Our clinical team is reviewing your information to assess whether the ECG function is right for you, and you’ll soon hear from Withings regarding that request. There is no need to do anything further.',
    },
    {
        title: 'We’re here to help.',
        text: (
            <>
                Our clinical team can answer general questions about the ECG at no additional cost to you if needed.{' '}
                <Bold>We are available Monday to Friday between 9 AM and 9 PM EST. </Bold> <br />
                <br />
                <br />
                Please note that we do not have access to your ECG data, so we will not be able to discuss results or
                diagnose you.
            </>
        ),
    },
];

const Title = styled('div')({
    color: '#4e52d3',
    fontSize: 20,
    fontFamily: 'gilroy-bold',
    marginBottom: 8,
    marginTop: 32,
});
const Text = styled('div')({
    color: '#171f29',
    fontSize: 18,
    fontFamily: 'gilroy-medium',
    whiteSpace: 'pre-line',
});

export default function CheckIn() {
    const location = useLocation();
    const requestId = new URLSearchParams(location.search).get('requestId');
    const token = new URLSearchParams(location.search).get('token');
    const job = new URLSearchParams(location.search).get('job');

    const [termsChecked, setTermsChecked] = useState(false);

    useEffect(() => {
        if (!requestId || !token) {
            redirectToHome();
        }
    }, []);

    // office hours is 9a to 9p EST on Mon to Fri
    const isOutsideOfficeHours =
        moment().tz('America/New_York').hour() >= 21 ||
        moment().tz('America/New_York').hour() < 9 ||
        !(new Date().getDay() % 6);

    const handleOnChangeCheckbox = () => {
        setTermsChecked((checked) => !checked);
    };

    /**
     * if job is present in the query params, redirect to the virtual visit application
     */
    const startVirtualVisit = () => {
        if (job) {
            window.localStorage.clear();

            const url = process.env.REACT_APP_VIRTUAL_VISIT_URL ?? 'https://virtual.staging.hbx.care';

            window.location.href = `${url}/?requestId=${requestId}&token=${token}`;
        }
    };

    return (
        <>
            <Header withings />
            <div className="withings-container">
                <main>
                    <div style={{ maxWidth: 700 }}>
                        <span className="hero-heading">ECG activation in progress.</span>
                        <div>
                            {FAQ.map((faq) => (
                                <>
                                    <Title>{faq.title}</Title>
                                    <Text>{faq.text}</Text>
                                </>
                            ))}
                        </div>
                        <div style={{ height: 36 }}></div>

                        {isOutsideOfficeHours && isProduction() ? null : (
                            <>
                                {!job && (
                                    <Checkbox
                                        label={
                                            <span className="withings-terms">
                                                I have read and agree to Heartbeat’s{' '}
                                                <a href="https://www.heartbeathealth.com/terms">terms of service</a>,{' '}
                                                <a href="https://www.heartbeathealth.com/terms">privacy policy</a>, and{' '}
                                                <a href="https://www.heartbeathealth.com/terms">ECG disclaimer</a>.
                                            </span>
                                        }
                                        onChange={isOutsideOfficeHours ? null : handleOnChangeCheckbox}
                                        dataId={'termsCheckbox'}
                                    />
                                )}
                                <div style={{ height: 40 }} />
                                <Button
                                    action={startVirtualVisit}
                                    disabled={!job && !termsChecked}
                                    type={'submit'}
                                    text={'Start a virtual visit'}
                                />
                            </>
                        )}
                    </div>
                </main>
                <div className="withings-cover-image"></div>
            </div>
        </>
    );
}
