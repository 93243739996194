import { Fab, styled } from '@material-ui/core';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC } from 'react';

import { WithingsSubscriptionReport } from './types';

const FullScreenRotated = styled('div')({
    transform: 'rotate(270deg) translate(-100%)',
    transformOrigin: 'top left',
    backgroundColor: '#fff',
    width: '100vh',
});

const FullScreenECGImage = styled('img')({
    maxWidth: '100%',
    paddingTop: 24,
});

const Top = styled('div')({
    backgroundColor: '#171f29',
    height: 40,
    zIndex: 100,
    width: '100vh',
    fontFamily: 'gilroy-bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const TopText = styled('span')({
    color: '#fff',
    zIndex: 100,
    position: 'relative',
    bottom: 4,
    textAlign: 'center',
    fontFamily: 'gilroy-bold',
});

const Arrow = styled(ArrowUpwardIcon)({
    position: 'relative',
    top: 2,
    paddingTop: 14,
    fontSize: 30,
});

const Background = styled('div')({
    backgroundColor: '#fff',
    width: '100vw',
    height: '100vh',
});

interface Props {
    report: WithingsSubscriptionReport;
    onClose: () => void;
}
const ReportECGImage: FC<Props> = ({ report, onClose }) => {
    return (
        <Background>
            <FullScreenRotated>
                <Top>
                    <TopText>
                        <Arrow />
                        TOP
                    </TopText>
                </Top>
                <FullScreenECGImage src={report?.ecgImageUrl} />
            </FullScreenRotated>

            <Fab color="primary" style={{ position: 'absolute', top: 8, right: 8 }} onClick={() => onClose()}>
                <CloseIcon />
            </Fab>
        </Background>
    );
};

export default ReportECGImage;
