import { useMutation } from '@apollo/client';
import { Popper, TextField, styled } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import DOBDatePicker from '../../components/DOBDatePicker';
import OutlineInput from '../../components/OutlineInput';
import { HEARTBEAT_HEALTH_PRACTICE_ID, REFERRAL_REASONS, SERVICES_LIST, STATES } from '../../constants';
import CREATE_TICKET from '../../state/network/graphql/queries/createTicket';
import CREATE_USER from '../../state/network/graphql/queries/createUser';
import { prepareFilesForUpload } from '../../utils';
import { UserType } from '../../utils/types/userType';
import { validator } from '../../utils/validation';

const LABEL = {
    [UserType.PROVIDER]: {
        firstName: 'Patient first name*',
        lastName: 'Patient last name*',
        birthDate: 'Patient DOB (MM/DD/YYYY)*',
        city: 'Primary City of Residence*',
        state: 'Primary State of Residence*',
        reason: 'Referral reason*',
        phoneNumber: 'Patient mobile number*',
        email: 'Patient email',
        zipCode: 'Patient zip code*',
        services: 'Services*',
        physicianName: 'Referring physician name*',
        physicianPhoneNumber: 'Referring physician phone number*',
        physicianFaxNumber: 'Referring physician fax number*',
        memberId: 'Member ID*',
        groupId: 'Group ID*',
        primaryCardholder: 'Primary cardholder*',
        primaryCardHolderDateOfBirth: 'Primary cardholder DOB*',
    },
    [UserType.PATIENT]: {
        firstName: 'First name*',
        lastName: 'Last name*',
        birthDate: 'DOB (MM/DD/YYYY)*',
        city: 'Primary City of Residence',
        state: 'Primary State of Residence*',
        reason: 'Referral reason',
        phoneNumber: 'Mobile number*',
        email: 'Email',
        zipCode: 'Zip code',
        services: 'Services',
        physicianName: 'Referring physician name',
        physicianPhoneNumber: 'Referring physician phone number',
        physicianFaxNumber: 'Referring physician fax number',
        memberId: 'Member ID',
        groupId: 'Group ID',
        primaryCardholder: 'Primary cardholder',
        primaryCardHolderDateOfBirth: 'Primary cardholder DOB',
    },
};

interface FormValues {
    firstName: string;
    lastName: string;
    birthDate?: Date;
    state: string;
    city: string;
    reason: string;
    otherReason: string;
    phoneNumber: string;
    email: string;
    physicianName: string;
    physicianPhoneNumber: string;
    physicianFaxNumber: string;
    attachments: File[];
    termsCheckbox: boolean;
    zipCode?: string;
    services: string;
    insuranceName: string;
    memberId: string;
    groupId: string;
    primaryCardholder: string;
    primaryCardHolderDateOfBirth?: Date;
}

const Dropzone = styled('section')({
    height: 150,
    width: '100%',
    backgroundColor: '#f9f9f9',
    borderRadius: 6,
    border: 'dashed 1px #bbbec2',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    overflow: 'scroll',
});
const Title = styled('label')({
    fontFamily: 'gilroy-medium',
    fontSize: 20,
    color: '#171f29',
});
const RightMessage = styled('div')({
    color: '#6e757d',
    fontSize: 14,
    fontFamily: 'gilroy-medium',
    height: 0,
    textAlign: 'right',
    position: 'relative',
    bottom: 24,
});
const FileListItem = styled('li')({
    marginBottom: 0,
    textAlign: 'left',
});

export default ({ userType, sourceOrganizationId }) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': ['.jpeg', '.png', '.pdf', '.webp', '.gif'] }, // allow specific file types
    });
    const navigate = useNavigate();
    const [createTicket, { data: ticket, error: ticketError, loading: ticketLoading }] = useMutation(CREATE_TICKET, {
        onCompleted: (res) => {
            if (res.createTicket.statusCode === 'SUCCESS') {
                navigate('/source-org-success');
                return;
            }
            alert(res.createTicket.errorMsg);
        },
        onError: (err) => alert(err.message),
    });
    const [createPatient, { data: patient, error: patientError, loading: patientLoading }] = useMutation(CREATE_USER, {
        onCompleted: async (res) => {
            if (res.createUser.statusCode !== 'SUCCESS') {
                alert(res.createUser.errorMsg);
                return;
            }
            const requesterId = res.createUser.userInfo.id;
            const ticket = {
                ticketInput: {
                    assignees: [],
                    attachments: await prepareFilesForUpload(acceptedFiles),
                    description:
                        formik.values.reason + (formik.values.otherReason && ' - ' + formik.values.otherReason),
                    jobType: formik.values.services,
                    practiceId: HEARTBEAT_HEALTH_PRACTICE_ID,
                    requesterId,
                    sourceOrganizationId,
                    tags: [],
                },
            };
            createTicket({
                variables: ticket,
            });
        },
        onError: console.error,
    });

    const submit = (values) => {
        const coverageInfo = {
            groupId: values.groupId,
            insuranceName: values.insuranceName,
            memberId: values.memberId,
            ...(!!values.primaryCardHolderDateOfBirth && {
                primaryCardHolderDateOfBirth: new Date(values.primaryCardHolderDateOfBirth.substring(0, 10))
                    .toISOString()
                    .split('T')[0],
            }),
            primaryCardHolderName: values.firstName + ' ' + values.lastName,
        };
        const newPatient = {
            practiceRoles: [],
            practiceId: HEARTBEAT_HEALTH_PRACTICE_ID,
            userInput: {
                phoneNumber: values.phoneNumber,
                createdForSourceOrganizationId: sourceOrganizationId.toString(),
                zipCode: values.zipCode,
                birthDate: new Date(values.birthDate.substring(0, 10)).toISOString().split('T')[0],
                email: values.email,
                billingAddress: {
                    city: '',
                    country: 'USA',
                    postalCode: values.zipCode,
                    state: values.state,
                    street: [],
                },
                firstName: values.firstName,
                lastName: values.lastName,
                mailingAddress: {
                    city: '',
                    country: 'USA',
                    postalCode: values.zipCode,
                    state: values.state,
                    street: [],
                },
                state: values.state,
                userType: 'PATIENT',
                coverageInfo,
            },
        };
        createPatient({ variables: newPatient });
    };

    const initialValues: FormValues = {
        firstName: '',
        lastName: '',
        birthDate: undefined,
        state: '',
        city: '',
        reason: '',
        phoneNumber: '',
        email: '',
        physicianName: '',
        physicianPhoneNumber: '',
        physicianFaxNumber: '',
        attachments: [],
        termsCheckbox: false,
        zipCode: undefined,
        services: userType === UserType.PATIENT ? 'Virtual Visit' : '',
        insuranceName: '',
        memberId: '',
        groupId: '',
        primaryCardholder: '',
        primaryCardHolderDateOfBirth: undefined,
        otherReason: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Yup.object({
            firstName: validator.firstName,
            lastName: validator.lastName,
            phoneNumber: validator.phoneNumber,
            birthDate: validator.birthDate,
            state: Yup.string().required('State is required'),
            city:
                userType === UserType.PROVIDER ? Yup.string().required('City is required') : Yup.string().notRequired(),
            reason:
                userType === UserType.PROVIDER
                    ? Yup.string().required('Referral reason is mandatory')
                    : Yup.string().notRequired(),
            email: validator.email,
            zipCode: userType === UserType.PROVIDER ? validator.zipCode : validator.zipCodeOptional,
            services: Yup.string().required(),
            ...(userType === UserType.PROVIDER && {
                physicianName: Yup.string().required(''),
                physicianPhoneNumber: validator.phoneNumber,
                physicianFaxNumber: Yup.string().required(''),
            }),
            insuranceName: Yup.string().required(''),
            memberId: userType === UserType.PROVIDER ? Yup.string().required('') : Yup.string().notRequired(),
            groupId: userType === UserType.PROVIDER ? Yup.string().required('') : Yup.string().notRequired(),
            primaryCardholder: userType === UserType.PROVIDER ? Yup.string().required('') : Yup.string().notRequired(),
            primaryCardHolderDateOfBirth:
                userType === UserType.PROVIDER ? validator.birthDate : Yup.string().notRequired(),
        }),
        onSubmit: submit,
    });

    useEffect(() => {
        formik.validateForm();
    }, [userType]);

    const handleOnChange = () => undefined;

    const files = acceptedFiles.map((file, i) => <FileListItem key={i}>{file.name}</FileListItem>);
    const isLoading = ticketLoading || patientLoading;

    if (!userType) return null;

    const referralReasonsRef = useRef(null);
    const stateRef = useRef(null);
    const servicesListRef = useRef(null);

    return (
        <>
            <form
                onSubmit={formik.handleSubmit}
                onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    if (target.name === 'attachments') {
                        return;
                    }
                    formik.handleChange(e);
                }}
            >
                {/* BASIC INFO */}
                <Title>Patient information</Title>
                <RightMessage>* Required</RightMessage>
                <br />
                <OutlineInput
                    error={formik.errors.firstName}
                    dataId={'firstName'}
                    label={LABEL[userType].firstName}
                    onChange={handleOnChange}
                />
                <OutlineInput
                    error={formik.errors.lastName}
                    dataId={'lastName'}
                    label={LABEL[userType].lastName}
                    onChange={handleOnChange}
                />
                <DOBDatePicker dataId={'birthDate'} label={LABEL[userType].birthDate} onChange={handleOnChange} />
                <div style={{ marginBottom: 24, position: 'relative' }} ref={referralReasonsRef}>
                    <Autocomplete
                        PopperComponent={(props) => <Popper {...props} container={() => referralReasonsRef.current} />}
                        closeIcon={null}
                        popupIcon={<KeyboardArrowDown style={{ color: '#171f29' }} />}
                        noOptionsText="No matching referral reasons found"
                        options={REFERRAL_REASONS}
                        getOptionLabel={(option) => option.label}
                        style={{ width: '100%' }}
                        onChange={(e, selected) => {
                            formik.setFieldValue('reason', selected?.value);
                        }}
                        blurOnSelect
                        renderInput={(params) => (
                            <TextField {...params} label={LABEL[userType].reason} variant="outlined" />
                        )}
                    />
                </div>
                {formik.values.reason === 'Other' && (
                    <OutlineInput dataId={'otherReason'} label={'Other reason*'} onChange={handleOnChange} />
                )}
                <OutlineInput
                    error={formik.errors.phoneNumber}
                    dataId={'phoneNumber'}
                    label={LABEL[userType].phoneNumber}
                    onChange={handleOnChange}
                />
                <OutlineInput
                    error={formik.errors.email}
                    dataId={'email'}
                    label={LABEL[userType].email}
                    onChange={handleOnChange}
                />
                <OutlineInput
                    error={formik.errors.city}
                    dataId={'city'}
                    label={LABEL[userType].city}
                    onChange={handleOnChange}
                />
                <div style={{ marginBottom: 24, position: 'relative' }} ref={stateRef}>
                    <Autocomplete
                        PopperComponent={(props) => <Popper {...props} container={() => stateRef.current} />}
                        closeIcon={null}
                        popupIcon={<KeyboardArrowDown style={{ color: '#171f29' }} />}
                        options={STATES}
                        getOptionLabel={(option) => option.label}
                        style={{ width: '100%' }}
                        onChange={(e, selected) => {
                            formik.setFieldValue('state', selected?.value);
                        }}
                        blurOnSelect
                        renderInput={(params) => (
                            <TextField {...params} label={LABEL[userType].state} variant="outlined" />
                        )}
                    />
                </div>
                <OutlineInput
                    error={formik.errors.zipCode}
                    dataId={'zipCode'}
                    label={LABEL[userType].zipCode}
                    onChange={handleOnChange}
                />
                <div style={{ marginBottom: 24, position: 'relative' }} ref={servicesListRef}>
                    <Autocomplete
                        PopperComponent={(props) => <Popper {...props} container={() => servicesListRef.current} />}
                        closeIcon={null}
                        popupIcon={<KeyboardArrowDown style={{ color: '#171f29' }} />}
                        options={SERVICES_LIST.map((service) => ({ label: service, value: service }))}
                        {...(userType === UserType.PATIENT
                            ? { value: { value: formik.values.services, label: formik.values.services } }
                            : null)}
                        getOptionSelected={(option, value) => option.value === value.value}
                        getOptionLabel={(option) => option.label}
                        style={{ width: '100%' }}
                        disabled={userType === UserType.PATIENT}
                        onChange={(e, selected) => {
                            formik.setFieldValue('services', selected?.value);
                        }}
                        blurOnSelect
                        noOptionsText="No matching services found"
                        renderInput={(params) => (
                            <TextField {...params} label={LABEL[userType].services} variant="outlined" />
                        )}
                    />
                </div>
                {/* PHYSICIAN INFO */}
                <Title>Referring physician</Title>
                {userType === UserType.PATIENT ? <br /> : <RightMessage>* Required</RightMessage>}
                <br />
                <OutlineInput
                    error={formik.errors.physicianName}
                    dataId={'physicianName'}
                    label={LABEL[userType].physicianName}
                    onChange={handleOnChange}
                />
                <OutlineInput
                    error={formik.errors.physicianPhoneNumber}
                    dataId={'physicianPhoneNumber'}
                    label={LABEL[userType].physicianPhoneNumber}
                    onChange={handleOnChange}
                />
                <OutlineInput
                    error={formik.errors.physicianFaxNumber}
                    dataId={'physicianFaxNumber'}
                    label={LABEL[userType].physicianFaxNumber}
                    onChange={handleOnChange}
                />
                {/* INSURANCE INFO */}
                <Title>Insurance information</Title>
                <RightMessage>* Required</RightMessage>
                <br />
                <OutlineInput dataId={'insuranceName'} label={'Insurance Name*'} onChange={handleOnChange} />
                <OutlineInput dataId={'memberId'} label={LABEL[userType].memberId} onChange={handleOnChange} />
                <OutlineInput
                    error={formik.errors.groupId}
                    dataId={'groupId'}
                    label={LABEL[userType].groupId}
                    onChange={handleOnChange}
                />
                <OutlineInput
                    error={formik.errors.primaryCardholder}
                    dataId={'primaryCardholder'}
                    label={LABEL[userType].primaryCardholder}
                    onChange={handleOnChange}
                />
                <DOBDatePicker
                    error={formik.errors.primaryCardHolderDateOfBirth}
                    dataId={'primaryCardHolderDateOfBirth'}
                    label={LABEL[userType].primaryCardHolderDateOfBirth}
                    onChange={handleOnChange}
                />
                <Title>Attachments (optional)</Title>
                <br />
                <br />
                <Dropzone>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} name="attachments" />
                        {files.length ? (
                            <ul>{files}</ul>
                        ) : (
                            <b>
                                Drag files to attach files or{' '}
                                <span style={{ color: '#d1007e', cursor: 'pointer' }}>click to upload</span>
                            </b>
                        )}
                    </div>
                </Dropzone>
                <br />
                <Checkbox
                    label={
                        <span className="withings-terms">
                            I agree to <a href="https://www.heartbeathealth.com/terms">Terms &amp; Conditions</a>
                        </span>
                    }
                    onChange={({ termsCheckbox }) => formik.setFieldValue('termsCheckbox', termsCheckbox)}
                    dataId={'termsCheckbox'}
                />
                <br />
                <br />
                <Button
                    disabled={
                        !!Object.keys(formik.errors).length ||
                        !formik.dirty ||
                        !formik.values.termsCheckbox ||
                        isLoading
                    }
                    type={'submit'}
                    text={isLoading ? 'Submitting...' : 'Submit'}
                />
            </form>
        </>
    );
};
