import React, { useEffect, useState } from 'react';
import HyperModal from 'react-hyper-modal';

import Button from '../../../../components/Button';
import OutlineInput from '../../../../components/OutlineInput';
import { renderGuideButton } from '../../EnrollmentGuide';

interface BpFormFields {
    systolic: number;
    diastolic: number;
}

export default ({ isOpen, close, onChange }) => {
    const [showGuide, setGuideVisibility] = useState(false);
    const [formValues, setFormValues] = useState<BpFormFields>();
    const [isFormValid, setFormValidity] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const hasError = {
        systolic: formValues?.systolic && (formValues.systolic < 20 || formValues.systolic > 300),
        diastolic: formValues?.diastolic && (formValues.diastolic < 20 || formValues.diastolic > 300),
    };
    const handleOnChange = (input) => {
        setFormValues((value) => Object.assign({}, value, input));
    };
    useEffect(() => {
        if (hasError.diastolic || hasError.systolic || !formValues?.systolic || !formValues?.diastolic)
            setFormValidity(false);
        else setFormValidity(true);
        setSubmitted(false);
    }, [formValues]);
    const submit = () => {
        onChange(formValues);
        setSubmitted(true);
    };
    const onGuideClose = () => {
        setGuideVisibility(false);
    };

    if (showGuide) return renderGuideButton('Blood pressure', onGuideClose);
    return (
        <HyperModal isFullscreen isOpen={isOpen} renderCloseIcon={() => null}>
            <div className="enroll-container measure-form-container">
                <h2 className="deep-grey-700">Enter blood pressure</h2>
                <h3 onClick={(_) => setGuideVisibility(true)} className="red-500">
                    How to measure blood pressure
                </h3>
                <OutlineInput
                    dataId={'systolic'}
                    hasError={!!hasError.systolic}
                    type={'number'}
                    label={'Systolic'}
                    onChange={handleOnChange}
                />
                <OutlineInput
                    hasError={!!hasError.diastolic}
                    type={'number'}
                    dataId={'diastolic'}
                    label={'Diastolic'}
                    onChange={handleOnChange}
                />
                <Button text="Submit" action={submit} disabled={!isFormValid || !!submitted} />
                <h3 onClick={close} className="red-500" style={{ textAlign: 'center' }}>
                    Go back
                </h3>
            </div>
        </HyperModal>
    );
};
