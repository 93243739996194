import { gql } from '@apollo/client';

export default gql`
    mutation setAppointmentDateTime(
        $sessionId: String!
        $ticketId: UUID!
        $appointmentTime: DateTime
        $appointmentTimeZone: String
    ) {
        setAppointmentDateTime(
            sessionId: $sessionId
            ticketId: $ticketId
            appointmentTime: $appointmentTime
            appointmentTimeZone: $appointmentTimeZone
        ) {
            errorMessage
            statusCode
        }
    }
`;
