import { createTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createTheme' {
    interface Theme {
        sidebarWidth: number;
        sidebarMobileHeight: number;
    }

    // allow configuration using `createMuiTheme`
    interface ThemeOptions {
        sidebarWidth?: number;
        sidebarMobileHeight?: number;
    }
}

export default createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#d1007e',
        },
    },
    sidebarWidth: 260,
    sidebarMobileHeight: 90,
});
