import { useMutation } from '@apollo/client';
import { Snackbar, SnackbarContent } from '@material-ui/core';
import React, { useState } from 'react';

import useUrlQuery from '../../../../hooks/useUrlQuery/useUrlQuery';
import AddEvent from '../../../../state/network/graphql/queries/addEvent';
import BPModal from '../Modals/BPModal';
import SingleValueModal from '../Modals/SingleValueModal';

interface Card {
    name: string;
    units: {
        units: string;
        displayUnits: string;
    };
    onChange: Function;
    id: string;
    value: any;
}

const defaultValue = (name: string) => (name === 'Blood pressure' ? '--/--' : '--');

export default ({ name, units, onChange, id, value }: Card) => {
    const isBP = name === 'Blood pressure';
    const isWeight = name === 'Weight';
    const [showModal, setModal] = useState<string | null>(null);
    const [addEvent] = useMutation(AddEvent);
    const query = useUrlQuery();
    const sessionId = query.get('sessionId');
    const [shouldShowSnackbar, setShouldShowSnackbar] = React.useState(false);
    const displayUnits = units.displayUnits || units.units;

    const transformedValue = (value) => {
        if (isWeight) return Number(Object.values(value)[0]);
        return isBP ? value : Object.values(value)[0];
    };

    const handleClose = (event, reason) => {
        setShouldShowSnackbar(false);
        setModal(null);
    };

    const handleOnChange = (value) => {
        onChange({ [id]: value });
        addEvent({
            variables: {
                sessionId,
                type: id,
                value: JSON.stringify({
                    value: transformedValue(value),
                    units: units.displayUnits || units.units,
                    source: 'manual',
                }),
                time: new Date(),
            },
        }).catch((e) => {
            console.error(e);
            alert("Couldn't store " + name + ': ' + e);
        });

        setShouldShowSnackbar(true);
    };
    value = value
        ? isBP
            ? value.systolic + '/' + value.diastolic
            : (Object.values(value)[0] as string)
        : defaultValue(name);

    return (
        <>
            <Snackbar
                style={{
                    margin: -10,
                    backgroundColor: '#1d827c',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={shouldShowSnackbar && !!showModal}
                autoHideDuration={1330}
                onClose={handleClose}
            >
                <SnackbarContent
                    style={{
                        padding: 20,
                        paddingTop: 12,
                        paddingBottom: 12,
                        color: '#fff',
                        backgroundColor: '#1d827c',
                    }}
                    message={showModal ? showModal + ' saved. Taking you back to main page.' : null}
                />
            </Snackbar>
            {isBP ? (
                <BPModal
                    isOpen={showModal === 'Blood pressure'}
                    onChange={handleOnChange}
                    close={() => setModal(null)}
                />
            ) : (
                <SingleValueModal
                    type={name}
                    isOpen={showModal === name}
                    onChange={handleOnChange}
                    close={() => setModal(null)}
                />
            )}
            <div className="measure-card" onClick={() => setModal(name)}>
                <div>
                    <h3 className="red-500">{name}</h3>
                    <span className="deep-grey-400">{displayUnits.replace('/mmHg', '')}</span>
                </div>
                <div className="measure-value">{value}</div>
            </div>
        </>
    );
};
