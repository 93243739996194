import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../components/Logo';
import useUrlQuery from '../../../hooks/useUrlQuery/useUrlQuery';
import ENROLLMENT_INVITATIONS from '../../../state/network/graphql/queries/enrollmentInvitations';
import EnrollmentForm from '../EnrollmentForm';
import '../index.css';

export default () => {
    const [invitation, setInvitation] = useState<any>(null);
    const navigate = useNavigate();
    const query = useUrlQuery();

    const sessionId = query.get('sessionId');
    const invitationToken = query.get('invitationToken');
    if (!sessionId || !invitationToken) {
        navigate('/expired', { state: { errorMessage: 'No invitation found 😔' } });
    }

    const {
        data: invitationsData,
        error,
        loading,
    } = useQuery(ENROLLMENT_INVITATIONS, {
        variables: { sessionId },
    });

    useEffect(() => {
        if (error) {
            console.error(error);
            navigate('/expired', { state: { errorMessage: error.message } });
        }
        if (!invitationsData) return;
        const invitationData = invitationsData.enrollmentInvitations.find((i) => i.token === invitationToken);
        if (!invitationData) {
            navigate('/expired', { state: { errorMessage: 'No invitation found 😔' } });
        }

        setInvitation(invitationData);
    }, [loading]);

    if (!invitation) return <Logo />;
    if (!invitation.provider) console.error(`Provider missing with invitationToken:${invitationToken}`);
    return (
        <div className="enroll-container">
            <Logo />
            {invitation.provider ? (
                <h2 className="deep-grey-700">
                    {invitation.provider.firstName} {invitation.provider.lastName} is enrolling you into Heartbeat
                    Measure.
                </h2>
            ) : (
                <h2 className="deep-grey-700">You're being invited to Heartbeat Measure</h2>
            )}
            <p className="deep-grey-600 subtitle" style={{ textAlign: 'left' }}>
                You’ve been prescribed a daily monitoring program for the following:
            </p>
            {invitation.options.interactions.map(({ name }) => (
                <p key={name} className="deep-grey-600 subtitle" style={{ textAlign: 'left' }}>
                    - {name}
                </p>
            ))}
            <br />
            <EnrollmentForm sessionId={sessionId} invitationToken={invitationToken} />
            <br />
            <br />
        </div>
    );
};
