import { FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import React from 'react';

export default ({ label, options, onChange, dataId }) => (
    <FormControl
        component="fieldset"
        onChange={(d) => onChange({ [dataId]: (d.target as HTMLInputElement).value === 'Yes' })}
    >
        <RadioGroup aria-label={label} name="hasMedicare">
            {options.map((option) => (
                <FormControlLabel key={option} value={option} control={<Radio />} label={option} />
            ))}
        </RadioGroup>
    </FormControl>
);
