import { gql } from '@apollo/client';

export default gql`
    mutation ($sessionId: String!, $invitationToken: UUID!, $enrollmentInfo: EnrollmentInfo!) {
        enroll(sessionId: $sessionId, invitationToken: $invitationToken, enrollmentInfo: $enrollmentInfo) {
            id
            program {
                id
            }
            invitedBy {
                lastName
            }
            active
            startDate
            options {
                interactions {
                    name
                    id
                    type
                }
            }
        }
    }
`;
