import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

import Header from '../../components/Header';

const useStyles = makeStyles({
    pageContainer: {
        backgroundColor: '#f9f9f9', // the side gutters for the page when screen size is larger than maxWidth on contentContainer
        height: 'calc(100vh - 80px)', // set page height to viewport minus header height
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        backgroundColor: 'white',
        height: '100%',
    },
    contentContainerNarrow: {
        maxWidth: '592px',
        padding: '32px 16px',
    },
    contentContainerWide: {
        maxWidth: '800px',
        padding: '32px',
    },
    pageTitle: {
        fontFamily: 'gilroy-bold',
        fontSize: '36px',
        fontWeight: 700,
        color: '#171f29',
    },
});

export interface BasicPageProps {
    pageTitle: string;
    pageWidthType?: 'narrow' | 'wide';
    children?: React.ReactNode;
}

/**
 * A reusable page layout component to render the header, page title, and children components for page content.
 * Supports all breakpoints and has gutters on tablet+ screen sizes.
 */
export default function BasicPage(props: BasicPageProps) {
    const styles = useStyles();
    const widthClassname =
        props.pageWidthType && props.pageWidthType === 'wide'
            ? `${styles.contentContainerWide}`
            : `${styles.contentContainerNarrow}`;

    return (
        <>
            <Header />
            <div className={styles.pageContainer}>
                <Container className={`${styles.contentContainer} ${widthClassname}`}>
                    <Typography component="h1" className={styles.pageTitle}>
                        {props.pageTitle}
                    </Typography>
                    {props.children}
                </Container>
            </div>
        </>
    );
}
