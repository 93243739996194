import { ProgramPatient } from '../../../utils/types/userType';

export enum EcgSubscriptionClassification {
    NormalSinusRhythm = 'NORMAL_SINUS_RHYTHM',
    SinusArrhythmia = 'SINUS_ARRHYTHMIA',
    SinusWithPACS = 'SINUS_WITH_PACS',
    SinusWithPVCS = 'SINUS_WITH_PVCS',
    SinusBradycardia = 'SINUS_BRADYCARDIA',
    SinusTachycardia = 'SINUS_TACHYCARDIA',
    AtrialFibrillation = 'ATRIAL_FIBRILLATION',
    AtrialFlutter = 'ATRIAL_FLUTTER',
    SupraVentricularTachycardia = 'SUPRAVENTRICULAR_TACHYCARDIA',
    VentricularTachycardia = 'VENTRICULAR_TACHYCARDIA',
    HeartBlock = 'HEART_BLOCK',
    OtherArrhythmia = 'OTHER_ARRHYTHMIA',
}

export enum EcgSubscriptionVentricularRate {
    Normal = 'NORMAL',
    Slow = 'SLOW',
    Fast = 'FAST',
}

export enum ECGSubscriptionStatusCode {
    Success = 'SUCCESS',
    NotFound = 'NOT_FOUND',
    NotAuthorized = 'NOT_AUTHORIZED',
    ReportExpired = 'REPORT_EXPIRED',
}

export enum EcgSubscriptionMorphology {
    Normal = 'NORMAL',
    PossiblyAbnormal = 'POSSIBLY_ABNORMAL',
}

export type WithingsSubscriptionReport = {
    createdAt: string;
    patient: ProgramPatient;
    ecgImageUrl: string;
    ecgClassification: EcgSubscriptionClassification;
    ecgCaptureDate: string;
    ventricularRate: EcgSubscriptionVentricularRate;
    morphology: EcgSubscriptionMorphology;
    providerName: string;
    type?: string;
};

export type WithingsReportListEntry = {
    id: string;
    createdAt: string;
    ecgCaptureDate: string;
};
