import moment from 'moment-timezone';

import { isProduction } from './env';

/*
  All hours are in EST - America/New_York time zone
*/
export function isOutsideOfficeHours(startHour: number, endHour: number): boolean {
    if (!isProduction()) {
        return false;
    }

    return (
        moment().tz('America/New_York').hour() >= endHour ||
        moment().tz('America/New_York').hour() < startHour ||
        !(new Date().getDay() % 6)
    );
}
