import { useQuery } from '@apollo/client';
import { styled } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header';
import GET_SOURCE_ORG from '../../state/network/graphql/queries/sourceOrganization';
import { isMobile } from '../../utils';
import { isOutsideOfficeHours } from '../../utils/date';
import { UserType } from '../../utils/types/userType';
import IntakeForm from './IntakeForm';
import OutsideOfficeHours from './OutsideOfficeHours';
import './index.css';

const Main = styled('main')({
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    color: '#171f29',
    backgroundColor: 'white',
});
const WarningLabel = styled('div')({
    justifyContent: 'center',
    width: '100%',
    padding: 18,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#ffeef8',
    color: '#171f29',
});
const H2 = styled('h2')({
    fontSize: isMobile ? 28 : 36,
    textAlign: 'center',
});
const InfoOutlined = styled(InfoOutlinedIcon)({
    height: 22,
    width: 22,
    color: '#ad0069',
    marginRight: 9,
});
const Container = styled('div')({
    maxWidth: 800,
    backgroundColor: 'white',
    width: '100%',
    padding: 16,
});

interface Props {
    type: UserType;
}
interface Params {
    id: string;
}

const SourceOrgLandingPage = ({ type }: Props) => {
    const { id } = useParams();
    const { data, loading, error } = useQuery(GET_SOURCE_ORG, {
        variables: {
            sourceOrganizationId: id,
        },
        onError: console.error,
    });

    if (isOutsideOfficeHours(9, 17)) return <OutsideOfficeHours />;
    if (loading) return null;
    return (
        <>
            <WarningLabel>
                <InfoOutlined />
                Heads up! This website cannot be used for patients who reside outside of the US.
            </WarningLabel>
            <Header sourceOrg={data} dark styles={{ backgroundColor: 'white' }} />
            <Main>
                <Container>
                    <H2>{type === UserType.PATIENT ? 'Tell us about yourself' : 'Tell us about your patient'}</H2>
                    <IntakeForm sourceOrganizationId={id} userType={type} />
                </Container>
            </Main>
        </>
    );
};

export default SourceOrgLandingPage;
