import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import Routes from './routes';
import client from './state/network/graphql/client';
import theme from './theme';
import './types';
import { initCrashReporting } from './utils/crashReporting';

initCrashReporting();

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ApolloProvider client={client}>
            <Routes />
        </ApolloProvider>
    </MuiThemeProvider>,
    document.getElementById('root'),
);
