import { gql } from '@apollo/client';

export default gql`
    query verifyPatientExists(
        $dateOfBirth: Date!
        $lastName: String!
        $partnerUserId: UUID!
        $phoneNumber: String!
        $ticketId: UUID!
    ) {
        verifyPatientExists(
            dateOfBirth: $dateOfBirth
            lastName: $lastName
            partnerUserId: $partnerUserId
            phoneNumber: $phoneNumber
            ticketId: $ticketId
        ) {
            session {
                statusJson
                id
                userId
            }
            userInfo {
                userType
            }
        }
    }
`;
