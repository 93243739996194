import { gql } from '@apollo/client';

export default gql`
    query sourceOrganization($sourceOrganizationId: String!) {
        sourceOrganization(sourceOrganizationId: $sourceOrganizationId) {
            name
            logoUrl
        }
    }
`;
