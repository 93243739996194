import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { Formik } from 'formik';
import moment, { Moment } from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../components/Button';
import DatePicker from '../../components/DatePickerField';
import DropdownField from '../../components/DropdownField';
import BasicPage from '../../components/Layout/BasicPage';
import SET_APPOINTMENT_DATE_TIME from '../../state/network/graphql/queries/setAppointmentDateTime';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mmZ';
// appointment length, in minutes
const TIME_SLOT_LENGTH = 30;

interface ScheduleFormProps {
    date: string;
    time: string;
}

const initialValues: ScheduleFormProps = {
    date: '',
    time: '',
};

// combine datepicker validation with a rule for time field
const validationSchema = Yup.object({
    date: Yup.string().required('Date is required'),
    time: Yup.string().required('Time is required'),
});

interface TimeSlotOption {
    value: string;
    label: string;
}

/**
 * Given a time, generate a user-friendly string to display the appointment timeslot in local time.
 *
 * e.g. 9:00am - 9:30am (CST)
 *
 * @param time the datetime object we want to generate a label for
 * @param timeslotLength the duration of the timeslot, in mintues
 * @returns user-friendly string of the timeslot
 */
const generateTimeslotLabel = (time: Moment, timeslotLength: number): string => {
    const timeInUserTimezone = time.tz(moment.tz.guess());
    const startTime = timeInUserTimezone.format('hh:mma');
    const endTime = timeInUserTimezone.clone().add(timeslotLength, 'minutes').format('hh:mma');

    return `${startTime} - ${endTime} (${timeInUserTimezone.zoneAbbr()})`;
};

/**
 * Generates timeslots for our dropdown element.
 *
 * Limits options to business hours and displays in users timezone.
 */
const generateTimeslotOptions = (startTime: Moment, endTime: Moment): TimeSlotOption[] => {
    const times: TimeSlotOption[] = [];

    // from given startTime to endTime, generate timeslots based on length of appointments.
    for (
        let time = startTime.clone().set({ second: 0, milliseconds: 0 });
        time < endTime;
        time.add(TIME_SLOT_LENGTH, 'minutes')
    ) {
        times.push({
            value: time.format(TIME_FORMAT),
            label: generateTimeslotLabel(time, TIME_SLOT_LENGTH),
        });
    }

    return times;
};

/**
 * Generate a list of timeslots for each day of the week. Each day is its own list of timeslots that can be passed into the timeslot dropdown.
 */
const getWeeklyTimeslots = (): TimeSlotOption[][] => {
    // Monday: 10:00am - 1:30pm EST
    const monday = generateTimeslotOptions(
        moment().tz('America/New_York').set({ hour: 10, minute: 0, second: 0, milliseconds: 0 }),
        moment().tz('America/New_York').set({ hour: 13, minute: 30, second: 0, milliseconds: 0 }),
    );
    // Tuesday: 2:00pm - 4:00pm EST, 5:00pm - 6:30pm EST
    const tuesday = generateTimeslotOptions(
        moment().tz('America/New_York').set({ hour: 14, minute: 0, second: 0, milliseconds: 0 }),
        moment().tz('America/New_York').set({ hour: 16, minute: 0, second: 0, milliseconds: 0 }),
    ).concat(
        generateTimeslotOptions(
            moment().tz('America/New_York').set({ hour: 17, minute: 0, second: 0, milliseconds: 0 }),
            moment().tz('America/New_York').set({ hour: 18, minute: 30, second: 0, milliseconds: 0 }),
        ),
    );
    // Wednesday: 10:00am - 1:30pm EST
    const wednesday = generateTimeslotOptions(
        moment().tz('America/New_York').set({ hour: 10, minute: 0, second: 0, milliseconds: 0 }),
        moment().tz('America/New_York').set({ hour: 13, minute: 30, second: 0, milliseconds: 0 }),
    );
    // Thursday: 10:00am - 12:00pm EST, 5:00pm - 6:30pm EST
    const thursday = generateTimeslotOptions(
        moment().tz('America/New_York').set({ hour: 10, minute: 0, second: 0, milliseconds: 0 }),
        moment().tz('America/New_York').set({ hour: 12, minute: 0, second: 0, milliseconds: 0 }),
    ).concat(
        generateTimeslotOptions(
            moment().tz('America/New_York').set({ hour: 17, minute: 0, second: 0, milliseconds: 0 }),
            moment().tz('America/New_York').set({ hour: 18, minute: 30, second: 0, milliseconds: 0 }),
        ),
    );
    // Friday: 12:00pm - 3:00pm EST
    const friday = generateTimeslotOptions(
        moment().tz('America/New_York').set({ hour: 12, minute: 0, second: 0, milliseconds: 0 }),
        moment().tz('America/New_York').set({ hour: 15, minute: 0, second: 0, milliseconds: 0 }),
    );

    // Include empty array for sunday and saturday indices to be able to map directly from moment.day().
    // Note: since we disable sunday and saurdays in the datepicker, we shouldn't ever be selecting these lists.
    return [[], monday, tuesday, wednesday, thursday, friday, []];
};

export default function Schedule() {
    const navigate = useNavigate();
    const location = useLocation();
    const styles = useStyles();
    const [timeslotOptions, setTimeslotOptions] = useState<TimeSlotOption[]>([]);
    const weeklyTimeslots = useMemo(() => getWeeklyTimeslots(), []); // timeslots per day are constant, only need to generate once.

    // backend generates these values as `token` and `requestId` to fit the pattern of virtual visit link generation
    const partnerUserId = new URLSearchParams(location.search).get('token');
    const ticketId = new URLSearchParams(location.search).get('requestId');
    const sessionId = new URLSearchParams(location.search).get('sessionId');

    useEffect(() => {
        if (!sessionId) {
            console.error(`Missing required query params. sessionId=${sessionId}`);
            navigate('/error', { state: { errorType: 'Invalid Session' } });
        }
        if (!partnerUserId || !ticketId) {
            console.error(`Missing required query params. token=${partnerUserId} requestId=${ticketId}`);
            navigate('/error');
        }
    }, [partnerUserId, ticketId, sessionId, navigate]);

    const [appointmentError, setAppointmentError] = useState<string>('');

    const [setAppointmentDateTime] = useMutation(SET_APPOINTMENT_DATE_TIME);

    const handleError = (errorMessage: string) => {
        console.error(errorMessage);
        if (errorMessage.toLowerCase().includes('session is expired')) {
            navigate('/error', { state: { errorType: 'Invalid Session' } });
        } else if (errorMessage.toLowerCase().includes('already set the appointment')) {
            setAppointmentError(
                'Your appointment has already been scheduled. If you need futher assistance, please call 646-586-9908.',
            );
        } else {
            setAppointmentError('Unable to schedule appointment, please check your selections and try again.');
        }
    };

    const handleSubmit = (values: ScheduleFormProps) => {
        const date = moment(values.date, DATE_FORMAT);
        const time = moment(values.time, TIME_FORMAT);
        const dateTime = date.clone().set({
            hour: time.hour(),
            minute: time.minute(),
            second: time.second(),
            millisecond: time.millisecond(),
        });
        const dateTimeIso = dateTime.toISOString(true);

        setAppointmentDateTime({
            variables: {
                ticketId: ticketId,
                sessionId: sessionId,
                appointmentTime: dateTimeIso,
                appointmentTimeZone: moment.tz.guess(),
            },
            onCompleted: (result) => {
                const statusCode: string = result?.setAppointmentDateTime?.statusCode as string;
                const errorMessage = result?.setAppointmentDateTime?.errorMessage as string;

                if (statusCode === 'SUCCESS') {
                    navigate('/p/kyr/success', {
                        state: {
                            date: dateTime.format('LL'),
                            timeSlot: generateTimeslotLabel(dateTime, TIME_SLOT_LENGTH),
                        },
                    });
                } else {
                    handleError(errorMessage);
                }
            },
            onError: (error) => {
                handleError(error.message);
            },
        });
    };

    return (
        <BasicPage pageTitle="Schedule your visit">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                validateOnBlur
                validateOnMount
            >
                {({ handleSubmit, setFieldValue, isValid, dirty, values }) => {
                    return (
                        <>
                            {appointmentError && <p className={styles.errorMessage}>{appointmentError}</p>}
                            <DatePicker
                                formikFieldName="date"
                                label="Select a date"
                                dateStringFormat={DATE_FORMAT}
                                onChange={(selectedDate: Moment) => {
                                    setFieldValue('time', '');
                                    setTimeslotOptions(weeklyTimeslots[selectedDate.day()]);
                                }}
                            />
                            <DropdownField
                                formikFieldName="time"
                                disabled={values.date === ''}
                                label="Select a timeslot"
                                options={timeslotOptions}
                            />
                            <Button disabled={!dirty || !isValid} action={handleSubmit} text="Schedule" />
                        </>
                    );
                }}
            </Formik>
        </BasicPage>
    );
}

const useStyles = makeStyles({
    errorMessage: {
        color: '#e10909',
        fontWeight: 600,
        fontSize: 18,
        marginTop: '-24px',
    },
});
