import { gql } from '@apollo/client';

export default gql`
    query patientEnrollments($sessionId: String!) {
        patientEnrollments(sessionId: $sessionId) {
            active
            program {
                id
            }
            options {
                interactions {
                    name
                    id
                    type
                    data
                }
            }
        }
    }
`;
