import * as Yup from 'yup';

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const zipCodeRegExp = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
const getDate18YearsAgo = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);
    return date;
};

const invalidZip = 'Invalid zip code';
const invalidEmail = 'Invalid email address';
const invalidPhone = 'Invalid phone number';

export const validator = {
    firstName: Yup.string().required(''),
    lastName: Yup.string().required(''),
    email: Yup.string().email(invalidEmail).optional(),
    zipCode: Yup.string().length(5, invalidZip).matches(zipCodeRegExp, invalidZip).required(''),
    zipCodeOptional: Yup.string().length(5, invalidZip).matches(zipCodeRegExp, invalidZip).notRequired(),
    phoneNumber: Yup.string().matches(phoneRegExp, invalidPhone).required(''),
    birthDate: Yup.date()
        .transform((value, originalValue) => {
            let newValue = originalValue;
            if (typeof newValue === 'string') newValue = newValue.replace(/_/g, '');
            if (newValue.length === 10) return new Date(newValue);
            return new Date();
        })
        .max(getDate18YearsAgo())
        .required(''),
    termsCheckbox: Yup.bool().isTrue('Please accept the Terms and Privacy Policy to continue').required(''),
};
