import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import React from 'react';

export default (props) => {
    const { label, onChange, dataId, defaultValue = null } = props;
    const [selectedDate, setSelectedDate] = React.useState<Date | null>(defaultValue);
    const [hasFocused, setHasFocused] = React.useState<boolean>(false);

    const handleDateChange = (date: Date | null) => {
        onChange({ [dataId]: date });
        setSelectedDate(date);
    };

    const hasError =
        selectedDate &&
        (isNaN(selectedDate.getTime()) ||
            new Date(new Date().getTime() - selectedDate.getTime()).getUTCFullYear() - 1970 < 18);

    return (
        <div style={{ marginBottom: 24 }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    autoComplete={'off'}
                    error={!!hasError}
                    helperText={hasError && hasFocused ? 'You have to be at least 18 years old' : null}
                    disableToolbar
                    inputVariant="outlined"
                    variant="dialog"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-outlined"
                    name={dataId}
                    label={label}
                    value={selectedDate}
                    maxDate={new Date()}
                    color="primary"
                    onBlur={() => setHasFocused(true)}
                    inputProps={{
                        inputMode: 'numeric',
                        'aria-autocomplete': 'none',
                    }}
                    InputAdornmentProps={{
                        style: { display: 'none' },
                    }}
                    onChange={handleDateChange}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};
