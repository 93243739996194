import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from '../../../components/Button';
import RadioControl from '../../../components/RadioControl';
import TextDatePicker from '../../../components/TextDatePicker';
import TextInputField from '../../../components/TextInputField';
import ENROLL from '../../../state/network/graphql/queries/enroll';

const useStyles = makeStyles({
    textInputField: {
        marginBottom: 24,
    },
});

interface FormValues {
    firstName: string;
    lastName: string;
    day: string;
    month: string;
    year: string;
    zipCode: string;
    textNotificationPreference: string; // time represented in 24 hours clock ex. 21:00
    hasMedicare?: boolean;
    timezone: string;
}

const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    day: '',
    month: '',
    year: '',
    zipCode: '',
    textNotificationPreference: '',
    hasMedicare: undefined,
    timezone: moment.tz.guess(true),
};

const validationSchema = Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    day: Yup.string()
        .matches(/^\d{2}$/, 'Must be 2 digits')
        .required('Day field is required'),
    month: Yup.string()
        .matches(/^\d{2}$/, 'Must be 2 digits')
        .required('Month field is required'),
    year: Yup.string()
        .matches(/^\d{4}$/, 'Must be 4 digits')
        .required('Year field is required'),
    zipCode: Yup.string()
        .matches(/^\d{5}(?:[-\s]\d{4})?$/, 'Invalid zip code')
        .required(),
    textNotificationPreference: Yup.string().required(),
    hasMedicare: Yup.boolean().required(),
});

export default ({ sessionId, invitationToken }) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const [enroll] = useMutation(ENROLL);
    const submit = (values) => {
        const birthDate = new Date(`${values.year}/${values.month}/${values.day}`).toISOString().split('T')[0];
        enroll({
            variables: {
                sessionId,
                invitationToken,
                enrollmentInfo: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    birthDate,
                    zipCode: values.zipCode,
                    timezone: values.timezone,
                    notificationPreferences: { notificationTime: values.textNotificationPreference },
                    coverageInfo: {
                        isInsuranceEligibleForRPM: values.hasMedicare,
                    },
                },
            },
        })
            .catch((e) => {
                console.error(e);
            })
            .then((_) => navigate('/enrollment-guide', { state: { patientName: values.firstName } }));
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={submit}
            validationSchema={validationSchema}
            validateOnBlur
            validateOnMount
        >
            {({ isValid, setFieldValue, handleSubmit }) => {
                return (
                    <>
                        <TextInputField name="firstName" placeholder="First Name*" className={styles.textInputField} />
                        <TextInputField name="lastName" placeholder="Last Name*" className={styles.textInputField} />
                        <TextDatePicker />
                        <TextInputField name="zipCode" placeholder={'Zip code*'} className={styles.textInputField} />
                        <TextInputField
                            name="textNotificationPreference"
                            placeholder={'Daily reminder time*'}
                            type="time"
                            className={styles.textInputField}
                        />
                        <h3 className="deep-grey-700 subtitle" style={{ textAlign: 'left' }}>
                            Are you enrolled in Medicare, Medicare Advantage, or Medicaid?
                        </h3>
                        <div className="radio-group">
                            <RadioControl
                                dataId={'hasMedicare'}
                                label="hasMedicare"
                                options={['Yes', 'No']}
                                onChange={({ hasMedicare }) => {
                                    setFieldValue('hasMedicare', hasMedicare);
                                }}
                            />
                        </div>
                        <br />
                        <Button text="Complete enrollment" action={handleSubmit} disabled={!isValid} />
                        <br />
                        <br />
                    </>
                );
            }}
        </Formik>
    );
};
