import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React from 'react';

import WithingsLogo from '../../assets/images/withings.svg';

interface Props {
    withings?: boolean;
    sourceOrg?: string;
    styles?: any;
    onBackButtonPress?: () => void;
    dark?: boolean;
}

const Withings = () => (
    <div className="withings-logo-container">
        In collaboration with: <img src={WithingsLogo} />
    </div>
);

const SourceOrg = ({ sourceOrg }) => {
    if (!sourceOrg.logoUrl) return null;
    return (
        <p className="source-org-logo-container">
            In collaboration with: <img alt={sourceOrg.name} src={sourceOrg.imageSrc} />
        </p>
    );
};

const Header = (props: Props) => {
    return (
        <div className="check-in-header" style={props.styles ? props.styles : undefined}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {props.onBackButtonPress && (
                    <ArrowBackIcon style={{ cursor: 'pointer', marginRight: 16 }} onClick={props.onBackButtonPress} />
                )}
                <div className={'horizontal-logo' + (props.dark ? '-dark' : '')} />
            </div>
            {props.withings && <Withings />}
            {props.sourceOrg && <SourceOrg sourceOrg={props.sourceOrg} />}
        </div>
    );
};

export default Header;
