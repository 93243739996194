import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

import brokenLinkImage from '../../assets/images/broken-link.png';
import errorImage from '../../assets/images/error.png';
import Header from '../../components/Header';

const useStyles = makeStyles({
    body: {
        color: '#434B55;',
        fontFamily: 'gilroy-medium',
        fontSize: 18,
        fontWeight: 400,
        marginTop: 16,
    },
    contentContainer: {
        marginTop: 32,
        padding: '0 16px',
        textAlign: 'center',
        width: '100%',
        maxWidth: 728,
    },
    pageContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#171f29',
        fontFamily: 'gilroy-medium',
        fontSize: 48,
        fontWeight: 700,
        letterSpacing: '-0.07px',
        lineHeight: 1.16,
    },
    textContainer: {
        textAlign: 'left',
    },
});

type ErrorScreenState = {
    errorType: string;
};

type ErrorPageContent = {
    image: string;
    imageAltText: string;
    title: string;
    description: string;
};

function getErrorPageContent(errorType: string): ErrorPageContent {
    switch (errorType) {
        case 'Invalid Session':
            return {
                image: brokenLinkImage,
                imageAltText: 'expired link',
                title: 'This check-in page no longer exists.',
                description:
                    "The link to this page has expired. If you were looking to check into Heartbeat, make sure you have clicked on the latest link we've sent you.",
            };
        default:
            return {
                image: errorImage,
                imageAltText: 'error',
                title: 'Oops! Something went wrong',
                description:
                    'Looks like we ran into a small snag. Close out of this page and click on the message link again.',
            };
    }
}

const ErrorScreen = () => {
    const styles = useStyles();
    const location = useLocation();
    let errorType = '';
    if (location.state) {
        const state = location.state as ErrorScreenState;
        errorType = state.errorType;
    }
    const { title, description, image, imageAltText } = getErrorPageContent(errorType);

    return (
        <div>
            <Header />
            <div className={styles.pageContainer}>
                <div className={styles.contentContainer}>
                    <img src={image} alt={imageAltText} />
                    <div className={styles.textContainer}>
                        <Typography component="h1" className={styles.pageTitle}>
                            {title}
                        </Typography>
                        <Typography component="p" className={styles.body}>
                            {description}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorScreen;
