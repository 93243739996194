import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../../components/Button';
import Logo from '../../../components/Logo';
import Text from '../../../components/Text';
import useUrlQuery from '../../../hooks/useUrlQuery/useUrlQuery';
import ENROLLMENT_INVITATIONS from '../../../state/network/graphql/queries/enrollmentInvitations';
import './styles.css';

interface Invitation {
    provider: {
        firstName: string;
        lastName: string;
    };
    token: string;
}

export default () => {
    const [invitation, setInvitation] = useState<Invitation | null>(null);
    const navigate = useNavigate();
    const query = useUrlQuery();

    const sessionId = query.get('sessionId');
    const invitationToken = query.get('invitationToken');
    if (!sessionId || !invitationToken) {
        navigate('/expired', { state: { errorMessage: 'No invitation found 😔' } });
    }
    const {
        data: invitationsData,
        error,
        loading,
    } = useQuery(ENROLLMENT_INVITATIONS, {
        variables: { sessionId },
    });

    useEffect(() => {
        if (error) {
            console.error(error);
            navigate('/expired', { state: { errorMessage: error.message } });
        }
        if (!invitationsData) return;
        const invitationData = invitationsData.enrollmentInvitations.find((i) => i.token === invitationToken);
        if (!invitationData) navigate('/expired', { state: { errorMessage: 'No invitation found 😔' } });
        setInvitation(invitationData);
    }, [loading]);

    return (
        <div className="invitation-contanier">
            <div>
                <Logo />
            </div>
            <span className="title invitation-title">
                {invitation
                    ? `${invitation?.provider.firstName} ${invitation?.provider.lastName} has invited you to Heartbeat Measure`
                    : 'Loading...'}
            </span>
            <Text
                leftAlign
                dark
                text={
                    'Heartbeat Measure lets you keep track of your vitals, such as blood pressure, heart rate, or weight.'
                }
            />
            <br />
            <Text leftAlign dark text={'For the best experience, download the free Heartbeat Health app.'} />
            <div className="invitation-buttons-container">
                <div className="invitation-button-container">
                    <Button
                        action={() => {
                            window.open('https://heart.app.link/', '_blank');
                        }}
                        text="Download the free app"
                        disabled={!invitation}
                    />
                </div>
                <div className="invitation-button-container">
                    <Button
                        secondary
                        action={() => {
                            window.location.href = `/enroll?sessionId=${sessionId}&invitationToken=${invitation?.token}`;
                        }}
                        text="Continue without the app"
                        disabled={!invitation}
                    />
                </div>
            </div>
        </div>
    );
};
