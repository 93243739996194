import { useLazyQuery } from '@apollo/client';
import { CircularProgress, makeStyles, useMediaQuery } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../../../components/Header';
import { redirectToHome } from '../../../routes';
import { ProgramPatient } from '../../../utils/types/userType';
import ReportDetail from './ReportDetail';
import ReportECGImage from './ReportECGImage';
import ReportList from './ReportList';
import { GET_REPORT_BY_ID, GET_REPORT_LIST } from './queries';
import { ECGSubscriptionStatusCode, WithingsReportListEntry, WithingsSubscriptionReport } from './types';

const Background = styled('div')({
    backgroundColor: '#fff',
    width: '100vw',
    minHeight: `calc(100vh - 80px)`,
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 72,
});

const Title = styled('div')({
    color: '#2e3192',
    fontSize: 38,
    fontFamily: 'gilroy-extrabold',
    marginBottom: 8,
    paddingTop: 32,
});

const Text = styled('div')({
    color: '#171f29',
    fontSize: 22,
    fontFamily: 'gilroy-medium',
    whiteSpace: 'pre-line',
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#fff',
    },
    inline: {
        color: '#d1007e',
        fontFamily: 'gilroy-semibold',
        fontSize: 18,
    },
    subtitle: {
        color: '#6e757d',
        fontFamily: 'gilroy-medium',
        fontSize: 16,
    },
}));

function WithingsReport() {
    const location = useLocation();

    const smallWidth = useMediaQuery('(max-width:786px)');
    const classes = useStyles();

    const [reports, setReports] = useState<WithingsReportListEntry[]>([]);

    const [report, setReport] = useState<WithingsSubscriptionReport>();
    const [reportId, setReportId] = useState<string | undefined>();
    const [showImage, setShowImage] = useState(false);

    const [getReportIds, { data, error, loading, called }] = useLazyQuery<
        {
            ecgSubscriptionReportList: Partial<{
                reports: WithingsReportListEntry[];
                patient: ProgramPatient;
                statusCode: ECGSubscriptionStatusCode;
                errorMessage: string;
                refreshUrl: string;
            }>;
        },
        { token: string }
    >(GET_REPORT_LIST, {
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.error(e);
        },
        onCompleted: (res) => {
            if (res.ecgSubscriptionReportList.statusCode === ECGSubscriptionStatusCode.ReportExpired) {
                window.location.href = `${res?.ecgSubscriptionReportList?.refreshUrl}`;
                return;
            }

            if (res.ecgSubscriptionReportList.errorMessage) {
                alert(res.ecgSubscriptionReportList.errorMessage);
                return;
            }

            const reportsSortedByDate: WithingsReportListEntry[] =
                res.ecgSubscriptionReportList?.reports?.sort((a, b) =>
                    moment(b.ecgCaptureDate).diff(moment(a.ecgCaptureDate)),
                ) ?? [];

            setReports(reportsSortedByDate);
        },
    });

    const [getReportById, { data: reportData, loading: loadingReport, variables }] = useLazyQuery<
        {
            ecgSubscriptionReport: {
                report: WithingsSubscriptionReport;
                statusCode: ECGSubscriptionStatusCode;
                errorMessage?: string;
            };
        },
        { reportId: string }
    >(GET_REPORT_BY_ID, {
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.error(e);
        },
        onCompleted: (res) => {
            if (res.ecgSubscriptionReport.statusCode === ECGSubscriptionStatusCode.ReportExpired) {
                window.location.href = `${data?.ecgSubscriptionReportList?.refreshUrl}`;
                return;
            }

            if (res.ecgSubscriptionReport.errorMessage) {
                alert(res.ecgSubscriptionReport.errorMessage);
                return;
            }

            setReport(res.ecgSubscriptionReport.report);
            setReportId(variables?.reportId);
        },
    });

    useEffect(() => {
        const token = new URLSearchParams(location.search).get('token');

        if (!token) {
            redirectToHome();
            return;
        }

        getReportIds({
            variables: {
                token,
            },
        });
    }, [location]);

    if (showImage && report?.patient) {
        return <ReportECGImage report={report} onClose={() => setShowImage(false)} />;
    }

    if (showImage || loadingReport || loading) {
        return <CircularProgress style={{ alignSelf: 'center' }} />;
    }

    if (report?.patient && reportId) {
        return (
            <ReportDetail
                report={report}
                smallWidth={smallWidth}
                setShowImage={setShowImage}
                setReportId={(val?: string) => {
                    setReport(undefined);
                    setReportId(undefined);
                }}
            />
        );
    }

    return (
        <>
            <Header withings styles={{ justifyContent: 'space-between' }} />
            <Background>
                <Title>
                    Welcome
                    {called && data?.ecgSubscriptionReportList?.patient?.firstName
                        ? `, ${data?.ecgSubscriptionReportList?.patient?.firstName}`
                        : ''}
                </Title>
                {called && reports.length ? (
                    <ReportList
                        reports={reports}
                        classes={classes}
                        smallWidth={smallWidth}
                        onReportClick={(id: string) => {
                            getReportById({ variables: { reportId: id } });
                        }}
                    />
                ) : (
                    <Text>You have no current files to download.</Text>
                )}
            </Background>
        </>
    );
}

export default WithingsReport;
