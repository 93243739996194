import { gql } from '@apollo/client';

export default gql`
    mutation createUser($practiceRoles: [PracticeRole!], $practiceId: UUID, $userInput: NewProgramUserInput!) {
        createUser(userInput: $userInput, practiceId: $practiceId, practiceRoles: $practiceRoles) {
            statusCode
            errorMsg
            userInfo {
                id
            }
        }
    }
`;
