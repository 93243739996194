import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AccessTimeOutlined, CalendarTodayOutlined } from '@mui/icons-material';
import React from 'react';
import { useLocation } from 'react-router-dom';

import BasicPage from '../../components/Layout/BasicPage';

const useStyles = makeStyles({
    dateTimeLabelText: {
        color: '#171f29',
        fontFamily: 'gilroy-semibold',
        fontSize: 18,
        fontWeight: 600,
        margin: '0 10px',
    },
    dateTimeText: {
        color: '#171f29',
        fontFamily: 'gilroy-medium',
        fontSize: 18,
        fontWeight: 500,
    },
    dateTimeTextContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    pageSubtitle: {
        borderTop: 'solid 1px #e8e9eb',
        color: '#171f29',
        fontFamily: 'gilroy-semibold',
        fontSize: 20,
        fontWeight: 600,
        marginTop: '-8px',
        paddingTop: 16,
    },
});

interface AppointmentConfirmationState {
    date: string;
    timeSlot: string;
}

/**
 * Page that displays a success message and informs user of next steps
 */
export default function IRhythmSuccess() {
    const styles = useStyles();
    const appointmentDetails = useLocation().state as AppointmentConfirmationState;
    const date = appointmentDetails?.date;
    const timeslot = appointmentDetails?.timeSlot;

    return (
        <BasicPage pageTitle="Your visit has been scheduled!">
            <Typography component="p" className={styles.pageSubtitle}>
                Appointment Confirmation:
            </Typography>
            <div className={styles.dateTimeTextContainer}>
                <CalendarTodayOutlined width={24} height={24} style={{ color: '#171f29' }} />
                <span className={styles.dateTimeLabelText}> Date: </span>
                <span className={styles.dateTimeText}>{date}</span>
            </div>
            <div className={styles.dateTimeTextContainer}>
                <AccessTimeOutlined width={24} height={24} style={{ color: '#171f29' }} />
                <span className={styles.dateTimeLabelText}> Timeslot: </span>
                <span className={styles.dateTimeText}>{timeslot}</span>
            </div>
        </BasicPage>
    );
}
