import { gql } from '@apollo/client';

export default gql`
    mutation createTicket($ticketInput: NewTicketInput) {
        createTicket(ticketInput: $ticketInput) {
            statusCode
            errorMessage
            ticket {
                displayId
                id
            }
        }
    }
`;
