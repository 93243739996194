export const EMPTY_USER_UUID = '00000000-0000-0000-0000-000000000000';

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = isIOS
    ? {
          width: 1280,
          height: 720,
          frameRate: 24,
      }
    : true;

export const HOW_TO_MEASURE = {
    'Blood pressure': `1. Measure your blood pressure at the same time each day.\n
  \ta. If you take it in the morning, it’s best to wait a few minutes before taking your blood pressure, but make sure you do it before eating or taking any medications.\n
  \tb. If you take it in the evening, take it 30 minutes prior to bedtime. \n
2. Avoid food, caffeine, tobacco, and alcohol for 30 minutes before taking a measurement.\n
3. Sit quietly before (five minutes if possible) and during monitoring. \n
4. Always use the same arm. Rest it, raised to the level of your heart on a table or chair.\n
5. Place the cuff on bare skin.\n
6. Take a repeat reading.\n
7. Your blood pressure is the top number (systolic) / bottom number (diastolic).\n
8. Your blood pressure cuff may also record HR (heart rate).\n`,

    Weight: `1. Be consistent. Weigh yourself at the same time each day.\n
2. Morning is ideal, after using the restroom and without wearing clothing or shoes.\n
3. Use the same scale each time. \n`,

    'Oxygen saturation': `1. Place the small clamp-like device on your finger (pointer or middle finger is easiest). This does not hurt.\n
2. Avoid wearing nail polish, which can cause error in the measurement.\n
3. The machine will give you a number which represents your oxygen saturation level. \n`,

    'Heart rate': `1. You can measure your heart rate with several different types of devices including a watch or another wearable, pulse oximeter (device that clips to your finger), or blood pressure monitor.\n
  2. It’s best to take your heart rate when you’re relaxed and sitting. \n
  3. Avoid taking it within two hours of exercise, a stressful event, or caffeine.\n
  4. The device will give you your heart rate, which represents the number of times your heart beats per minute.\n
  `,
};

export const REFERRAL_REASONS = [
    { label: 'Arrhythmia (Atrial fibrillation)', value: 'Arrhythmia (Atrial fibrillation)' },
    { label: 'Arrhythmia(Other)', value: 'Arrhythmia(Other)' },
    { label: 'Heart failure', value: 'Heart failure' },
    { label: 'Peripheral Vascular Disorder', value: 'Peripheral Vascular Disorder' },
    { label: 'Chest pain', value: 'Chest pain' },
    { label: 'Palpitations', value: 'Palpitations' },
    { label: 'Shortness of breath', value: 'Shortness of breath' },
    { label: 'Heart-related notification from smart device', value: 'Heart-related notification from smart device' },
    { label: 'Other', value: 'Other' },
];

export const SERVICES_LIST = [
    '1-Lead ECG',
    '6-Lead ECG',
    '12-Lead ECG',
    'Echocardiogram',
    'Virtual Visit',
    'Prescription',
];

export const STATES = [
    { label: 'Alabama', value: 'AL' },
    {
        label: 'Alaska',
        value: 'AK',
    },
    {
        label: 'Arizona',
        value: 'AZ',
    },
    {
        label: 'Arkansas',
        value: 'AR',
    },
    {
        label: 'California',
        value: 'CA',
    },
    {
        label: 'Colorado',
        value: 'CO',
    },
    {
        label: 'Connecticut',
        value: 'CT',
    },
    {
        label: 'Delaware',
        value: 'DE',
    },
    {
        label: 'District of Columbia',
        value: 'DC',
    },
    {
        label: 'Florida',
        value: 'FL',
    },
    {
        label: 'Georgia',
        value: 'GA',
    },
    {
        label: 'Guam',
        value: 'GU',
    },
    {
        label: 'Hawaii',
        value: 'HI',
    },
    {
        label: 'Idaho',
        value: 'ID',
    },
    {
        label: 'Illinois',
        value: 'IL',
    },
    {
        label: 'Indiana',
        value: 'IN',
    },
    {
        label: 'Iowa',
        value: 'IA',
    },
    {
        label: 'Kansas',
        value: 'KS',
    },
    {
        label: 'Kentucky',
        value: 'KY',
    },
    {
        label: 'Louisiana',
        value: 'LA',
    },
    {
        label: 'Maine',
        value: 'ME',
    },
    {
        label: 'Maryland',
        value: 'MD',
    },
    {
        label: 'Massachusetts',
        value: 'MA',
    },
    {
        label: 'Michigan',
        value: 'MI',
    },
    {
        label: 'Minnesota',
        value: 'MN',
    },
    {
        label: 'Mississippi',
        value: 'MS',
    },
    {
        label: 'Missouri',
        value: 'MO',
    },
    {
        label: 'Montana',
        value: 'MT',
    },
    {
        label: 'Nebraska',
        value: 'NE',
    },
    {
        label: 'Nevada',
        value: 'NV',
    },
    {
        label: 'New Hampshire',
        value: 'NH',
    },
    {
        label: 'New Jersey',
        value: 'NJ',
    },
    {
        label: 'New Mexico',
        value: 'NM',
    },
    {
        label: 'New York',
        value: 'NY',
    },
    {
        label: 'North Carolina',
        value: 'NC',
    },
    {
        label: 'North Dakota',
        value: 'ND',
    },
    {
        label: 'Ohio',
        value: 'OH',
    },
    {
        label: 'Oklahoma',
        value: 'OK',
    },
    {
        label: 'Oregon',
        value: 'OR',
    },
    {
        label: 'Palau',
        value: 'PW',
    },
    {
        label: 'Pennsylvania',
        value: 'PA',
    },
    {
        label: 'Puerto Rico',
        value: 'PR',
    },
    {
        label: 'Rhode Island',
        value: 'RI',
    },
    {
        label: 'South Carolina',
        value: 'SC',
    },
    {
        label: 'South Dakota',
        value: 'SD',
    },
    {
        label: 'Tennessee',
        value: 'TN',
    },
    {
        label: 'Texas',
        value: 'TX',
    },
    {
        label: 'Utah',
        value: 'UT',
    },
    {
        label: 'Vermont',
        value: 'VT',
    },
    {
        label: 'Virginia',
        value: 'VA',
    },
    {
        label: 'Washington',
        value: 'WA',
    },
    {
        label: 'West Virginia',
        value: 'WV',
    },
    {
        label: 'Wisconsin',
        value: 'WI',
    },
    {
        label: 'Wyoming',
        value: 'WY',
    },
];

export const HEARTBEAT_HEALTH_PRACTICE_ID =
    process.env.REACT_APP_HEARTBEAT_HEALTH_PRACTICE_ID || '3146d1f7-d6ff-4329-beab-5624e00d73f9';
