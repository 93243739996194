import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

import Logo from '../../../components/Logo';
import useUrlQuery from '../../../hooks/useUrlQuery/useUrlQuery';
import PatientEnrollments from '../../../state/network/graphql/queries/patientEnrollments';
import Card from './Card';

export default () => {
    const [formValues, setFormValues] = useState<any>();
    const [enrollment, setEnrollment] = useState<any>();
    const [steps, setSteps] = useState(0);
    const [showConfetti, setShowConfetti] = useState(true);
    const query = useUrlQuery();
    const sessionId = query.get('sessionId');
    const programId = query.get('programId');
    const navigate = useNavigate();

    const {
        data: enrollmentData,
        error,
        loading,
    } = useQuery(PatientEnrollments, {
        variables: { sessionId },
    });

    useEffect(() => {
        if (error) {
            navigate('/expired', { state: { errorMessage: error.message } });
            console.error(error);
        }
        if (!enrollmentData) return;
        setEnrollment(enrollmentData.patientEnrollments.find((data) => data.program.id === programId && data.active));
    }, [loading]);

    const setValue = (data) => {
        setFormValues((formValues_) => Object.assign({}, formValues_, data));
    };

    useEffect(() => {
        if (!formValues) return;
        const interactions = enrollment?.options.interactions.map((interaction) => interaction.id);
        const completedSteps = Object.keys(formValues).filter((key) => interactions.indexOf(key) > -1).length;
        setSteps(completedSteps);
    }, [formValues]);

    const isComplete = steps === enrollment?.options.interactions.length;
    if (isComplete) {
        setTimeout(() => setShowConfetti(false), 10000);
    }
    if (!enrollmentData) return <Logo />;
    if (!enrollment?.options?.interactions?.length)
        return (
            <div className="enroll-container">
                <Logo />
                <br />
                <h3 className="deep-grey-400 subtitle" style={{ textAlign: 'left' }}>
                    {new Date()
                        .toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                        .toUpperCase()}
                </h3>
                <h2 className="deep-grey-700" style={{ textAlign: 'left', marginBottom: 0 }}>
                    This program is no longer valid
                </h2>
            </div>
        );
    return (
        <div className="enroll-container">
            <Logo />
            <br />
            <h3 className="deep-grey-400 subtitle" style={{ textAlign: 'left' }}>
                {new Date().toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).toUpperCase()}
            </h3>
            {isComplete && showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
            <h2 className="deep-grey-700" style={{ textAlign: 'left', marginBottom: 0 }}>
                {isComplete
                    ? 'Thank you! You completed your measurements for today.'
                    : 'Select measurement(s) to record for today.'}
            </h2>

            <p className="deep-grey-400" style={{ fontSize: 18, fontFamily: 'gilroy-medium' }}>
                {isComplete
                    ? 'Your measurements have been sent to your doctor, you will receive another link tomorrow for entry.'
                    : 'Enter any vitals that you have completed today. You can return to this page later to enter more data.'}
            </p>

            <br />
            <br />
            {
                // eslint-disable-next-line no-unsafe-optional-chaining
                new Array(...enrollment?.options.interactions)
                    .sort((interaction, nextInteraction) => Number(interaction.name > nextInteraction.name) - 1)
                    .map((i) => {
                        const { units, displayUnits } = i.data && JSON.parse(i.data);
                        return (
                            <Card
                                value={formValues && formValues[i.id]}
                                key={i.id}
                                id={i.id}
                                name={i.name}
                                units={{ units, displayUnits }}
                                onChange={(value) => setValue(value)}
                            />
                        );
                    })
            }
            <br />
            <h3
                className={isComplete ? 'teal-600' : 'deep-grey-400'}
                style={{ textAlign: 'center', fontFamily: 'gilroy-bold' }}
            >
                {`${steps}/${enrollment?.options.interactions.length} COMPLETE`}
            </h3>
        </div>
    );
};
