import DateFnsUtils from '@date-io/date-fns';
import { DatePicker as MuiDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Holidays from 'date-holidays';
import { useField } from 'formik';
import moment, { Moment } from 'moment-timezone';
import React, { useMemo } from 'react';

/**
 * Determines if the given date is a Sunday or Saturday
 */
const isWeekend = (date: Moment) => {
    return date.day() === 0 || date.day() === 6;
};

/**
 * Determines if the given date is a company holiday.
 *
 * Limits holidays to US and only uses the public & optional categories.
 * @see https://www.npmjs.com/package/date-holidays#types-of-holidays
 *
 * Current holidays (public + optional):
 *  - New Year’s Day
 *  - Martin Luther King Jr. Day
 *  - Washington’s Birthday
 *  - Memorial Day
 *  - Juneteenth
 *  - Independence Day
 *  - Labor Day
 *  - Columbus Day
 *  - Veterans Day
 *  - Thanksgiving Day
 *  - Christmas Day
 *  - Christmas Eve
 */
const isHoliday = (date: Moment) => {
    const hd = new Holidays();
    hd.init('US', { types: ['optional', 'public'] });
    const holidaysOnDate = hd.isHoliday(date.toDate());
    return !holidaysOnDate ? holidaysOnDate : holidaysOnDate.length > 0;
};

/**
 * Recursively finds the next date that is not a holiday or a weekend
 */
const getNextBusinessDay = (currentDate: Moment): Moment => {
    if (isWeekend(currentDate) || isHoliday(currentDate)) {
        return getNextBusinessDay(currentDate.add(1, 'day'));
    } else {
        return currentDate;
    }
};

/**
 * Given two dates, determine if they are the same date, regardless of time.
 */
const isSameDate = (dateOne: Moment, dateTwo: Moment) => {
    return dateOne.startOf('day').diff(dateTwo.startOf('day')) === 0;
};

interface DatePickerProps {
    label: string;
    formikFieldName: string;
    dateStringFormat: string; // how the Date should be formatted as a string (e.g. YYYY-MM-DD)
    placeholder?: string;
    onChange?: (newDate: Moment) => void;
}

const DatePicker = (props: DatePickerProps) => {
    const { label, formikFieldName, dateStringFormat, placeholder } = props;
    const [field, meta, helper] = useField<string>(formikFieldName);

    // Only generate this once to avoid calculating for each day rendered in the datepicker. This value is consistent since it is relative to the current date.
    // edge case: if user is filling this form out and the time changes to 12am without them refreshing their screen, this will be off by a day.
    const nextBusinessDay = useMemo(() => getNextBusinessDay(moment().startOf('day').add(1, 'day')), []);

    const handleBlur = () => {
        helper.setTouched(true);
    };

    const handleDateChange = (date: Date) => {
        props.onChange?.(moment(date));
        helper.setValue(moment(date).format(dateStringFormat));
    };

    const shouldDisableDate = (date: Date | null) => {
        if (!date) {
            return false;
        }

        const dateMoment = moment(date);

        // disable current day, the next business day, weekends, and holidays
        return (
            isSameDate(dateMoment, moment()) ||
            isSameDate(dateMoment, nextBusinessDay) ||
            isWeekend(dateMoment) ||
            isHoliday(dateMoment)
        );
    };

    return (
        <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiDatePicker
                    autoOk
                    disableToolbar
                    disablePast
                    error={meta.touched && !!meta.error}
                    format="MM/dd/yyyy"
                    helperText={meta.touched ? meta.error : null}
                    inputVariant="outlined"
                    label={label}
                    onBlur={handleBlur}
                    onChange={(date) => date && handleDateChange(date)}
                    placeholder={placeholder}
                    shouldDisableDate={shouldDisableDate}
                    value={field.value ? moment(field.value, dateStringFormat) : null}
                    variant="inline"
                    InputProps={{ endAdornment: <CalendarTodayIcon /> }}
                />
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default DatePicker;
