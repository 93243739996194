import { styled } from '@material-ui/styles';
import moment from 'moment';
import React, { FC } from 'react';

import { WithingsSubscriptionReport } from './types';

interface Props {
    report: WithingsSubscriptionReport;
}

const ReportTable: FC<Props> = (props) => {
    const { report } = props;

    const TableTitle = styled('div')({
        color: '#d1007e',
        fontSize: 15,
        fontFamily: 'gilroy-bold',
        whiteSpace: 'pre-line',
        textTransform: 'uppercase',
    });
    const TableColumn0 = styled('p')({
        textAlign: 'left',
        marginTop: 0,
        marginBottom: 0,
        marginRight: 8,
        marginLeft: -3,
        whiteSpace: 'nowrap',
        color: '#6e757d',
        textTransform: 'uppercase',
        fontFamily: 'gilroy-semibold',
        lineHeight: 1.2,
        letterSpacing: 0,
    });

    const TableColumn1 = styled('p')({
        textAlign: 'left',
        marginTop: 0,
        marginBottom: 0,
        whiteSpace: 'nowrap',
        color: '#171f29',
        textTransform: 'uppercase',
        fontFamily: 'gilroy-bold',
        lintHeight: 1.43,
        letterSpacing: 0,
    });

    return (
        <>
            <br />
            <TableTitle>ECG Report</TableTitle>
            <br />

            <table>
                <tbody>
                    <tr>
                        <td>
                            <TableColumn0>Patient</TableColumn0>
                        </td>
                        <td>
                            <TableColumn1>
                                {report?.patient.firstName} {report?.patient.lastName}
                            </TableColumn1>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TableColumn0>Birthdate</TableColumn0>
                        </td>
                        <td>
                            <TableColumn1>{moment(report?.patient.birthDate).format('MM/DD/YYYY')}</TableColumn1>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TableColumn0>Patient ID</TableColumn0>
                        </td>
                        <td>
                            <TableColumn1>{report?.patient.displayId}</TableColumn1>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TableColumn0>Device</TableColumn0>
                        </td>
                        <td>
                            <TableColumn1>Withings ScanWatch</TableColumn1>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TableColumn0>Capture</TableColumn0>
                        </td>
                        <td>
                            <TableColumn1>{moment(report?.ecgCaptureDate).format('MM/DD/YYYY LT')}</TableColumn1>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <TableColumn0>Interpretation</TableColumn0>
                        </td>
                        <td>
                            <TableColumn1>{moment(report?.createdAt).format('MM/DD/YYYY')}</TableColumn1>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default ReportTable;
