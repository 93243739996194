import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';

const getError = (type) => {
    switch (type) {
        case 'Zip code':
            return 'Invalid zip code';
        default:
            return 'Please enter a valid value';
    }
};

export default ({
    label,
    onBlur = () => undefined,
    onFocus = () => undefined,
    onChange,
    type = 'text',
    hasError = false,
    dataId,
    defaultValue = '',
    error = '',
}) => {
    const [hasFocused, setHasFocused] = useState(false);
    const hasError_ = hasError || error;
    const helperText = hasError ? getError(label) : error ? error : null;
    return (
        <div style={{ marginBottom: 24 }}>
            <TextField
                error={!!hasError_}
                helperText={hasFocused && helperText}
                color="primary"
                name={dataId}
                id="outlined-basic"
                label={label}
                variant="outlined"
                defaultValue={defaultValue}
                onFocus={onFocus}
                onBlur={() => {
                    setHasFocused(true);
                    onBlur?.();
                }}
                inputProps={{
                    inputMode: type === 'number' ? 'numeric' : 'text',
                }}
                onChange={(d) => onChange({ [dataId]: type === 'number' ? Number(d.target.value) : d.target.value })}
                type={type}
            />
        </div>
    );
};
