import { styled } from '@material-ui/core/styles';
import React from 'react';

import officeHoursClock from '../../assets/images/check-in/office-hours-clock.png';
import { Body, CopyContainer } from '../../components/Layout/CheckInFlow';

const Container = styled('img')({
    alignSelf: 'center',
    marginTop: '18px',
    marginBottom: '24px',
});
const OfficeHoursClock = styled('img')({
    alignSelf: 'center',
    marginTop: '18px',
    marginBottom: '24px',
});

const OutsideOfficeHours = () => {
    return (
        <Body className="atrium-check-in-body">
            <OfficeHoursClock src={officeHoursClock} alt="" />
            <h1>We are currently closed.</h1>
            <CopyContainer>
                <p>
                    We operate everyday Monday – Friday from 9am to 5pm. Check back to this page during our normal
                    business hours.
                </p>
            </CopyContainer>
        </Body>
    );
};

export default OutsideOfficeHours;
