import React from 'react';

interface TitleProps {
    text: JSX.Element | Element | string | undefined;
    dark?: boolean;
}
export default (props: TitleProps) => {
    return (
        <span className="title" style={{ color: props.dark ? '#171F29' : '#fff' }}>
            {props.text}
        </span>
    );
};
