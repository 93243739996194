import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        iconLeft: {
            marginRight: theme.spacing(1),
            display: 'inline-block',
            height: '100%',
            '& > svg': {
                position: 'relative',
                top: 5,
            },
        },
        iconRight: {
            marginLeft: theme.spacing(1),
            display: 'inline-block',
            height: '100%',
            '& > svg': {
                position: 'relative',
                top: 5,
            },
        },
    }),
);

interface ButtonProps {
    text: string;
    action?: Function;
    disabled?: boolean;
    loading?: boolean;
    secondary?: boolean;
    narrow?: boolean;
    noFill?: boolean;
    noPad?: boolean;
    type?: 'button' | 'submit' | 'reset';
    icon?: React.ReactNode;
    iconPosition?: 'left' | 'right';
}
export default (props: ButtonProps) => {
    const classes = useStyles();
    const disabled = props.disabled || props.loading;
    const icon = props.icon || null;
    const iconPosition = props.iconPosition || 'left';

    return (
        <button
            disabled={disabled}
            className={`button ${props.secondary && 'secondary-button'} ${disabled && 'disabled-button'} ${
                props.noFill && 'no-fill-button'
            } ${props.narrow && 'narrow-button'} ${props.noPad && 'no-pad-button'}`}
            type={props.type || 'button'}
            onClick={() => props.action && props.action()}
        >
            {icon && iconPosition !== 'right' && <span className={classes.iconLeft}>{icon}</span>}
            <span>{props.text}</span>
            {icon && iconPosition === 'right' && <span className={classes.iconRight}>{icon}</span>}
        </button>
    );
};
