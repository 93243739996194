import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const URL = process.env.REACT_APP_SERVICE_URL || 'https://hb-edge.dev-useast1.heartbeathealth.com/graphql';

const httpLink = new HttpLink({
    uri: URL,
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: httpLink,
});

export default client;
