import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import ErrorScreen from './screens/Error';
import ExpiredScreen from './screens/Expired';
import IRhythmLandingPage from './screens/IRhythm/IRhythmLandingPage';
import NoFindingsIRhythm from './screens/IRhythm/NoFindings';
import PatientVerification from './screens/IRhythm/PatientVerification';
import ScheduleIRhythm from './screens/IRhythm/Schedule';
import SuccessIRhythm from './screens/IRhythm/Success';
import Enroll from './screens/RPM/Enroll';
import EnrollmentGuide from './screens/RPM/EnrollmentGuide';
import Invitation from './screens/RPM/Invitation';
import Measure from './screens/RPM/Measure';
import SourceOrgLandingPage from './screens/SourceOrgLandingPage';
import SuccessSourceOrgLandingPage from './screens/SourceOrgLandingPage/Success';
import WithingsCheckIn from './screens/Withings/CheckIn';
import WithingsReport from './screens/Withings/Report';
import { isProduction } from './utils/env';
import { UserType } from './utils/types/userType';

export const redirectToHome = () =>
    isProduction()
        ? (window.location.href = 'https://heartbeathealth.com/')
        : console.info('Should redirect to heartbeathealth.com');

export default () => (
    <BrowserRouter>
        <Routes>
            <Route path="/source-org/:id/provider" element={<SourceOrgLandingPage type={UserType.PROVIDER} />} />
            <Route path="/source-org/:id/patient" element={<SourceOrgLandingPage type={UserType.PATIENT} />} />
            <Route path="/source-org-success" element={<SuccessSourceOrgLandingPage />} />

            <Route path="/p/withings/report" element={<WithingsReport />} />
            <Route path="/p/withings" element={<WithingsCheckIn />} />

            <Route path="/p/kyr/verify-patient" element={<PatientVerification />} />
            <Route path="/p/kyr/schedule-visit" element={<ScheduleIRhythm />} />
            <Route path="/p/kyr/success" element={<SuccessIRhythm />} />
            <Route path="/p/kyr/completed" element={<NoFindingsIRhythm />} />
            <Route path="/p/kyr" element={<IRhythmLandingPage />} />

            <Route path="/expired" element={<ExpiredScreen />} />
            <Route path="/enroll" element={<Enroll />} />
            <Route path="/entry" element={<Measure />} />
            <Route path="/invitation" element={<Invitation />} />
            <Route path="/enrollment-guide" element={<EnrollmentGuide />} />
            <Route path="/error" element={<ErrorScreen />} />
        </Routes>
    </BrowserRouter>
);
