import { Table, TableBody, TableCell, TableHead, TableRow, Typography, styled } from '@material-ui/core';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import moment from 'moment';
import React, { FC } from 'react';

import { WithingsReportListEntry } from './types';

const ListItem = styled('div')({
    display: 'flex',
    height: 72,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: 'solid 1px #e8e9eb',
    borderBottom: 'solid 1px #e8e9eb',
    cursor: 'pointer',
});

const Text = styled('div')({
    color: '#171f29',
    fontSize: 22,
    fontFamily: 'gilroy-medium',
    whiteSpace: 'pre-line',
});

const TableHeader = styled('div')({
    color: '#6e757d',
    fontSize: 15,
    fontFamily: 'gilroy-bold',
    textTransform: 'uppercase',
    marginTop: 24,
    marginBottom: 16,
});

interface Props {
    reports: WithingsReportListEntry[];
    classes: any;
    smallWidth: boolean;
    onReportClick: (id: string) => void;
}
const ReportList: FC<Props> = (props) => {
    const { reports, classes, smallWidth } = props;

    return (
        <>
            <Text>Your Heartbeat ECG report is ready.</Text>
            <div>
                {!smallWidth ? (
                    <Table style={{ marginTop: 24 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        color: '#6e757d',
                                        textTransform: 'uppercase',
                                        fontFamily: 'gilroy-bold',
                                        borderColor: '#BBBEC2',
                                    }}
                                >
                                    Report Type
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: '#6e757d',
                                        textTransform: 'uppercase',
                                        fontFamily: 'gilroy-bold',
                                        borderColor: '#BBBEC2',
                                    }}
                                    align="left"
                                >
                                    Date of Capture
                                </TableCell>
                                <TableCell align="right" style={{ borderColor: '#BBBEC2' }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reports.map((row) => (
                                <TableRow
                                    key={`${row.id}-${row.ecgCaptureDate}`}
                                    hover={true}
                                    style={{ cursor: 'pointer', backgroundColor: '#fff' }}
                                    onClick={() => props.onReportClick(row.id)}
                                >
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{
                                            borderColor: '#BBBEC2',
                                            color: '#d1007e',
                                            fontFamily: 'gilroy-semibold',
                                            fontSize: 18,
                                        }}
                                    >
                                        ECG Report
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{
                                            color: '#6e757d',
                                            borderColor: '#BBBEC2',
                                            fontFamily: 'gilroy-medium',
                                            fontSize: 18,
                                        }}
                                    >
                                        {moment(row.ecgCaptureDate).format('ll')}
                                    </TableCell>
                                    <TableCell align="right" style={{ borderColor: '#BBBEC2' }}>
                                        <ChevronRightIcon style={{ color: '#BBBEC2' }} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <div className={classes.root}>
                        <TableHeader>Report Type</TableHeader>
                        {reports.map((row, index) => (
                            <>
                                <ListItem
                                    onClick={() => props.onReportClick(row.id)}
                                    key={`${row.ecgCaptureDate}-${index}`}
                                >
                                    <div>
                                        <Typography component="span" variant="body2" className={classes.inline}>
                                            ECG Report
                                        </Typography>

                                        <Typography variant="body2" className={classes.subtitle}>
                                            {moment(row.ecgCaptureDate).format('ll')}
                                        </Typography>
                                    </div>

                                    <ChevronRightIcon style={{ color: '#BBBEC2' }} />
                                </ListItem>
                            </>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default ReportList;
