import { gql } from '@apollo/client';

export default gql`
    query enrollmentInvitations($sessionId: String!) {
        enrollmentInvitations(sessionId: $sessionId) {
            token
            program {
                name
                id
            }
            provider {
                firstName
                lastName
            }
            options {
                interactions {
                    name
                    type
                }
            }
        }
    }
`;
